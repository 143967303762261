import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../Services/Providers/CotizacionStore";
import { useDescripcionesStore } from "../../Services/Providers/DescripcionesStore";
import { useConfiguracionEmpresa } from "../../Services/Providers/useConfiguracionEmpresa";
import { TelefonosCompañia, telefonos } from "../../Assets/telefonosCompanias";
import { SegHeaderLogo } from "../../Components/SegUI/SegHeaderLogo/SegHeaderLogo";
import segurarse from "../../Assets/img/logo_segurarse.svg";
import { SegStepper } from "../../Components/SegUI/SegStepper/SegStepper";
import useSEOConfig from "../../hooks/seo/useSEOConfig";
import obtenerParametros from "../../Services/API/Parametros";
import obtenerDescripciones from "../../Services/API/Descripciones";
import { Marca } from "../../Components/Slides/Moto/Cotizacion/Marca";
import { SegButton } from "../../Components/SegUI/SegButton/SegButton";
import { SegBackButton } from "../../Components/SegUI/SegBackButton/SegBackButton";
import { Modelo } from "../../Components/Slides/Moto/Cotizacion/Modelo";
import { Anio } from "../../Components/Slides/Moto/Cotizacion/Anio";
import { Version } from "../../Components/Slides/Moto/Cotizacion/Version";
import { Nombre } from "../../Components/Slides/Moto/Cotizacion/Nombre";
import { Apellido } from "../../Components/Slides/Moto/Cotizacion/Apellido";
import { Email } from "../../Components/Slides/Moto/Cotizacion/Email";
import { Telefono } from "../../Components/Slides/Moto/Cotizacion/Telefono";
import { FechaNacimiento } from '../../Components/Slides/Moto/Cotizacion/FechaNacimiento';
import { Genero } from "../../Components/Slides/Moto/Cotizacion/Genero";
import { CodigoPostalManual } from "../../Components/Slides/Moto/Cotizacion/CodigoPostalManual";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
`;

const Loading = styled.section`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #fff;
`;

export interface IPasos {
    step: number;
    subStep: number;
}

export interface CotizadorProps {
    step?: number;
    subStep?: number;
}


export const Cotizador = ({ step = 0, subStep = 1 }: CotizadorProps) => {

    const { compania } = useParams();
    const nav = useNavigate();

    const Icon = styled.svg`
    color: #343434 !important;
  `;
    const referer = document.referrer;
    const landingUrl = document.location.href;

    const [steps, setSteps] = useState<IPasos>({ step, subStep });
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
    const {
        descripcionesValues,
        setDescripcionesValues,
    } = useDescripcionesStore();

    const [paramsCargando, setParamsCargando] = useState(false);
    const { origen, config, setConfig } = useConfiguracionEmpresa();
    const [phones, setPhones] = useState<TelefonosCompañia[]>(telefonos);
    const [justifyContent, setJustifyContent] = useState(
        "justify-content-center"
    );


    useSEOConfig({
        title: "Moto",
        marcaBlanca: (origen !== "SEGURARSE") ? config.origen.charAt(0).toUpperCase() +
            origen.slice(1).toLowerCase() : undefined,
        robots: (origen !== "SEGURARSE") ? "noindex, nofollow" : ""
    })


    useEffect(() => {
        function ajustarJustifyContent() {
            if (window.innerWidth >= 768) {
                setJustifyContent("col-md-3 text-md-end align-self-center");
            } else {
                setJustifyContent(
                    "d-flex align-items-center justify-content-center mt-3"
                );
            }
        }

        // Llamar a la función al cargar la página y al cambiar el tamaño de la ventana
        ajustarJustifyContent();
        window.addEventListener("resize", ajustarJustifyContent);

        // Limpieza del listener cuando el componente se desmonta
        return () => {
            window.removeEventListener("resize", ajustarJustifyContent);
        };
    }, []);


    const backButtonClick = () => {
        if(step === 1 && subStep === 1) return 
        nav(-1);
    };

    useEffect(() => {
        setSteps((prev) => {
            return {
                ...prev,
                step: step,
                subStep: subStep,
            };
        });
    }, [step, subStep]);

    useEffect(() => {
        if (!cotizacionValues.parametrosIniciales || cotizacionValues.parametrosIniciales.rubro !== "MOTO") {
            setParamsCargando(true);
            const fetchDataParams = async () => {
                const response: any = await obtenerParametros({ rubro: "Moto" });
                if (response) {
                    setCotizacionValues({
                        //...cotizacionValues,
                        parametrosIniciales: response.data,
                        referer: referer,
                        landingUrl: landingUrl,
                    });
                    const estilos = response.data.estilos;
                    if (estilos) {
                        setConfig({
                            ...JSON.parse(estilos),
                            origen: response.data.origen
                        }, response.data.origen)
                    } else {
                        setConfig({
                            origen: response.data.origen,
                            images: {
                                logo: "",
                            },
                            colors: {
                                accent: "",
                                primary: "",
                                secondary: "",
                                tertiary: "",
                                icons: "",
                                navbar: "",
                                segu_stepper: ""
                            },
                            config: {
                                cookies: true
                            }
                        }, response.data.origen)
                    }
                }
            };

            fetchDataParams().then(() => {
                setParamsCargando(false);
            });
        }
    }, []);

    useEffect(() => {
        if (origen !== "SEGURARSE") {
            const favicon: any = document.querySelector('link[rel="icon"]');
            if (favicon) {
                favicon.href = config.images.favicon
            } else {
                const link = document.createElement('link');
                link.rel = 'icon';
                link.href = config.images.favicon || "";
                document.head.appendChild(link);
            }
        }
    }, [origen])

    useEffect(() => {
        if (
            cotizacionValues.parametrosIniciales &&
            descripcionesValues?.length === 0
        ) {
            const fetchDataDesc = async (
                rubro: string,
                origen: string,
                tipoCotizador: string
            ) => {
                const response: any = await obtenerDescripciones(
                    rubro,
                    origen,
                    tipoCotizador
                );
                if (response) {
                    setDescripcionesValues(response.data.api_data);
                }
            };
            fetchDataDesc(
                //cotizacionValues.parametrosIniciales.rubro,
                "moto",
                cotizacionValues.parametrosIniciales.origen,
                cotizacionValues.parametrosIniciales.tipoCotizador
            );
        }
    }, [cotizacionValues.parametrosIniciales]);

    useEffect(() => {
        if (cotizacionValues.contactoId) {
            setCotizacionValues({
                ...cotizacionValues,
                contactoId: undefined,
            });
        }
    }, [
        cotizacionValues.patente,
        cotizacionValues.marca,
        cotizacionValues.modelo,
        cotizacionValues.año,
        cotizacionValues.version,
        cotizacionValues.nombre,
        cotizacionValues.apellido,
        cotizacionValues.email,
        cotizacionValues.telefono,
        cotizacionValues.fechaNac,
        cotizacionValues.genero,
        cotizacionValues.localidad,
        cotizacionValues.provincia,
    ]);


    const tel = phones.filter((t: TelefonosCompañia) => {
        if (t.clave === compania) {
            return t;
        }
    });


    const renderVista = () => {
        switch (steps?.step) {
            case 0:
                switch (steps?.subStep) {/*
                    case 1:
                        return <InicioNormal config={config} setSteps={setSteps} />;
                    case 2:
                        return <ResultadoPatente config={config} setSteps={setSteps} />;
                */}
                break;
            case 1:
                switch (steps?.subStep) {
                    case 1:
                        return <Marca config={config} setSteps={setSteps} />;
                    case 2:
                        return <Modelo config={config} setSteps={setSteps} />
                    case 3:
                        return <Anio config={config} setSteps={setSteps} />
                    case 4:
                        return <Version config={config} setSteps={setSteps} />
                    case 5:
                        return <Nombre config={config} setSteps={setSteps} nombreValue={cotizacionValues.nombre} />
                    case 6:
                        return <Apellido config={config} setSteps={setSteps} apellidoValue={cotizacionValues.apellido} />
                    case 7:
                        return <Email config={config} setSteps={setSteps} emailValue={cotizacionValues.email} />
                    case 8:
                        return <Telefono config={config} setSteps={setSteps} telefonoValue={cotizacionValues.telefono} />
                    case 9:
                        return <FechaNacimiento config={config} setSteps={setSteps} fechaNacValue={cotizacionValues.fechaNac} />
                    case 10:
                        return <Genero config={config} setSteps={setSteps} />
                    case 11:
                        return <CodigoPostalManual config={config} setSteps={setSteps} />
                }
                break;
            default:
                return <h1>loading</h1>;
        }
    };

    if (paramsCargando) {
        return (
            <Loading>
                <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                    <p className=''><strong>Cargando</strong></p>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </Loading>
        )
    }


    return (
        <>
            <PageContainer>
                <div className="sticky-top"
                    style={{ background: origen !== "SEGURARSE" ? config.colors.navbar : "#ffffff" }}
                >
                    <div className="container pt-3 pb-3">
                        {
                            config.origen !== "SEGURARSE" ?
                                <div className="row">
                                    <div className="col-md-6 offset-md-3 text-center d-flex justify-content-center gap-3">
                                        {" "}
                                        {/* Centra el contenido en la columna */}
                                        <SegHeaderLogo
                                            img={config.images.logo}
                                            alt="Logo Segurarse"
                                            width={window.innerWidth > 768 ? "130px" : "100px"}
                                            style={{ filter: "brightness(0) invert(1)" }}
                                        />
                                        <div style={{ height: "100%", width: "2px", backgroundColor: "#ffffff" }}></div>
                                        <SegHeaderLogo
                                            img={segurarse}
                                            alt="Logo Segurarse"
                                            width={window.innerWidth > 768 ? "140px" : "110px"}
                                            style={{ filter: "brightness(0) invert(1)" }}
                                        />
                                    </div>

                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-6 offset-md-3 text-center">
                                        {" "}
                                        {/* Centra el contenido en la columna */}
                                        <SegHeaderLogo
                                            img={segurarse}
                                            alt="Logo Segurarse"
                                            width="160px"
                                        />
                                    </div>
                                    {compania && tel.length > 0 && (
                                        <div className={`${justifyContent}`}>
                                            {" "}
                                            {/* Alinea el contenido al centro vertical y a la derecha */}
                                            <a
                                                href={"tel:" + tel[0]?.telefono}
                                                className="tel align-items-center gap-2"
                                            >
                                                <Icon className="icon">
                                                    <use xlinkHref="#tel" />
                                                </Icon>
                                                {tel[0]?.telefono}
                                            </a>
                                        </div>
                                    )}
                                </div>
                        }
                    </div>
                </div>

                <div>
                    <SegStepper
                        icon="moto"
                        label="Cotizá tu moto online y encontrá el mejor precio"
                        stepActive={steps.step === 0 ? 1 : steps.subStep < 5 ? 1 : 2}
                        isStepper={true}
                        finalStep='$'
                        bgColor={origen !== "SEGURARSE" ? config.colors.segu_stepper : undefined}
                        iconColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                        lineColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                        labelColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                        stepInactiveBgColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                        stepActiveBorderColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                        stepActiveTextColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                    />
                </div>
                {steps?.subStep >= 1 && steps?.step! === 1 && (
                    <div className="cot-bc">
                        <div className="container mt-2">
                            <SegBackButton
                                marca={`${cotizacionValues?.marca?.label
                                    ? cotizacionValues?.marca?.label
                                    : ""
                                    }`}
                                version={`${cotizacionValues?.version?.label
                                    ? cotizacionValues?.version?.label
                                    : ""
                                    }`}
                                modelo={`${cotizacionValues?.modelo?.label
                                    ? cotizacionValues?.modelo?.label
                                    : ""
                                    }`}
                                anio={`${cotizacionValues?.año?.label
                                    ? cotizacionValues?.año?.label
                                    : ""
                                    }`}
                                onClick={backButtonClick}
                                backgroundColor={origen !== "SEGURARSE" ? config.colors.accent : undefined}
                            />
                        </div>
                    </div>
                )}
                {paramsCargando ? (
                    <div className="d-flex justify-content-center py-5">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    renderVista()
                )}

                {
                    config.config.cookies &&
                    <CookiesComponent />
                }
            </PageContainer>
        </>
    )
}


const CookiesComponent = () => {

    const [showConsent, setShowConsent] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const [modalAnimationClass, setModalAnimationClass] = useState("fade-in-top");
    const [backdropClass, setBackdropClass] = useState("fade-in");

    useEffect(() => {
        const checkCookieConsent = () => {
            const cookieExists = document.cookie
                .split(";")
                .some((item) => item.trim().startsWith("cookies="));
            if (!cookieExists) {
                setShowConsent(true);
            } else {
                const cookieValue = document.cookie.replace(
                    /(?:(?:^|.*;\s*)cookies\s*=\s*([^;]*).*$)|^.*$/,
                    "$1"
                );
                if (cookieValue !== "yes") {
                    setShowConsent(true);
                }
            }
        };

        checkCookieConsent();
        return () => { };
    }, []);

    useEffect(() => {
        const handleScrollLock = (showConsent: boolean) => {
            if (showConsent) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
        };
        handleScrollLock(showConsent);

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [showConsent]);


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                cerrarModal();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showConsent]);
    const handleConsent = () => {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 5);
        document.cookie = `cookies=yes; max-age=${5 * 365 * 24 * 60 * 60}`;
        setShowConsent(false);
    };

    const cerrarModal = () => {
        setBackdropClass("fade-out");
        setModalAnimationClass("fade-out-top");
        setTimeout(() => {
            setShowConsent(false);
        }, 500);
    };

    return (
        <>
            {showConsent && (
                <div className={`modal-backdrop show ${backdropClass}`}></div>
            )}
            {showConsent && (
                <div ref={modalRef}>
                    <div
                        className={`fixed-bottom cookie-consent ${modalAnimationClass}`}
                        id="cookieconsent"
                        tabIndex={-1}
                        aria-labelledby="modal"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        aria-label="Close"
                                        onClick={cerrarModal}
                                    ></button>
                                </div>
                                <div className="row align-items-center pb-lg-2 pt-2">
                                    <div className="col-lg-9">
                                        <div className="ff-mukta fs-16 fs-lg-30 fw-bold lh-sm text-primary">
                                            ¡Bienvenido a Segurarse.com.ar!
                                        </div>
                                        <p className="my-2 fs-13 fs-lg-14 lh-sm">
                                            En este sitio utilizamos cookies. Las cookies son
                                            necesarias para el correcto funcionamiento y la
                                            seguridad de la página, además nos ayudan a
                                            proporcionarte una mejor experiencia de navegación. Para
                                            más información consulta nuestra{" "}
                                            <a
                                                href={
                                                    "https://segurarse.com.ar/politicas-privacidad"
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                                className="policy-button"
                                            >
                                                Políticas de Privacidad
                                            </a>
                                            . <br /> Segurarse.com.ar nunca te solicitará
                                            información confidencial, datos bancarios o de tarjetas
                                            de crédito ya sea por mail, teléfono, WhatsApp o redes
                                            sociales. Ante cualquier llamado o mensaje dudoso
                                            comunicate con tu asesor o a través de los canales
                                            indicados en la sección de Contacto.
                                        </p>
                                    </div>
                                    <div className="col-lg-3 py-3 py-lg-0">
                                        <SegButton
                                            className="fs-14 fs-lg-16 btn-primary btn-modal"
                                            label="Acepto"
                                            type="submit"
                                            onClick={() => {
                                                handleConsent();
                                            }}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}