import { useState } from 'react';
import { SegTextarea } from '../../../../../Components/SegUI/SegTextArea/SegTextArea';
import { SegButton } from '../../../../../Components/SegUI/SegButton/SegButton';
import { StepComponentProps } from '../../Siniestros';
import { SiniestroData, useSiniestroStore } from '../../../../../Services/Providers/SiniestroStore';
import styled from '@emotion/styled';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GuardarSiniestro } from '../../../../../Services/API/GuardarSiniestro';
import { guardarArchivoSiniestro } from '../../../../../Services/API/GuardarArchivoSiniestro';

const CustomCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 4px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ span {
    background-color: #007bff;
  }

  input:checked ~ span:after {
    display: block;
  }
`;

export const DañoParcial = ({ setSteps, config }: StepComponentProps) => {

  const { formState, handleSubmit, control, setValue, trigger,  watch } = useForm({
    defaultValues: {
      relatoHechos: '',
      tiposDaño: {
        granizo: false,
        inundacion: false,
        incendio: false
      }
    }
  });

  const [fileBase64, setFileBase64] = useState<{ name: string, base64: string, type: string }>();
  const [errorMessage, setErrorMessage] = useState('');
  const [enviado, setEnviado] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // NUEVO: Obtener store para guardar datos
  const { siniestroValues, setSiniestroValues } = useSiniestroStore();
  const tiposDaño = watch("tiposDaño", {} as any);

  const renderTitle = (): string => {
    switch (siniestroValues.tipoSiniestro) {
      case "AccidenteTransito": return "Accidente de tránsito"
      case "RoturaCristalesCerraduras": return "Rotura de cristales/cerraduras"
      case "RoboTotal": return "Robo total"
      case "RoboParcial": return "Robo parcial"
      case "DanioParcial": return "Daño parcial"
    }
    return ""
  }

  function convertirImagenABase64(e: any) {
    const archivo = e.target.files[0]; // Obtiene el archivo seleccionado

    const lector = new FileReader();

    if (archivo) {
      lector.onloadend = function () {
        const base64String = (lector.result as string).split(',')[1];
        setFileBase64({
          name: archivo.name,
          base64: base64String,
          type: archivo.type.split('/')[0]
        })
      };

      lector.readAsDataURL(archivo);
    }
  }


  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsLoading(true)
    setSiniestroValues({
      ...siniestroValues,
      relatoHechos: data.relatoHechos,
      tiposDanio: data.tiposDaño,
      //denunciaPolicial: fileBase64 ? fileBase64.base64 : null,
    });
    const resp = await GuardarSiniestro({
      ...siniestroValues,
      relatoHechos: data.relatoHechos,
      tiposDanio: data.tiposDaño,
    } as SiniestroData);

    if (resp.caseId) {
      setErrorMessage("")
      if (fileBase64) {
        await guardarArchivoSiniestro(resp.caseId, fileBase64 ? fileBase64.base64 : "", fileBase64?.type);
      }

      setEnviado(true)
    } else {
      setErrorMessage("Ocurrio un error, intentalo otra vez.");
    }
    setIsLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row justify-content-center pt-4 pb-4">
      <div className="col-md-8">
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}

        <legend className='mb-4 fw-bold'>{renderTitle()}</legend>

        <Controller
          name="relatoHechos"
          control={control}
          rules={{ required: "El relato de los hechos es obligatorio" }}
          render={({ field, fieldState }) => (
            <SegTextarea
              label="Relato de los hechos"
              placeHolder="Describe lo más detallado posible cómo sucedió el daño"
              width={100}
              name=""
              className='mb-3'
              onChange={(e) => {
                field.onChange(e);
                setValue("relatoHechos", e.target.value);
                trigger("relatoHechos");
              }}
              value={field.value}
              leyendaError={fieldState?.error?.message}
              borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
              focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
            />

          )}
        />


        <div className="card p-3 mb-3">
          <h5>Tipo de daño</h5>
          <Controller
            name="tiposDaño"
            control={control}
            rules={{
              validate: (value) =>
                Object.values(value || {}).some((checked) => checked)
                  ? true
                  : "Debes seleccionar al menos un tipo de daño.",
            }}
            render={({ field: { value = {}, onChange } }) => (
              <>
                <CustomCheckbox>
                  Granizo
                  <input
                    type="checkbox"
                    checked={value.granizo || false}
                    onChange={(e) =>
                      onChange({ ...value, granizo: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Inundación
                  <input
                    type="checkbox"
                    checked={value.inundacion || false}
                    onChange={(e) =>
                      onChange({ ...value, inundacion: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Incendio
                  <input
                    type="checkbox"
                    checked={value.incendio || false}
                    onChange={(e) =>
                      onChange({ ...value, incendio: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>
              </>
            )}
          />

          {/* Mensaje de error */}
          {formState.errors.tiposDaño && <p style={{ color: "red" }}>{formState.errors.tiposDaño.message}</p>}
        </div>

        {tiposDaño.incendio && (
          <div className="mb-3">
            <label className="form-label">Denuncia de bomberos</label>
            <div className="custom-file-input">
              <input
                type="file"
                className="form-control"
                accept="image/*,application/pdf"
                onChange={convertirImagenABase64}
                required
              />
              {fileBase64 && (
                <small className="text-success">
                  Archivo seleccionado: {fileBase64.name}
                </small>
              )}
            </div>
            <small className="text-muted d-block mt-2">
              Es imprescindible que efectúes la denuncia en la estación de bomberos correspondiente
            </small>
          </div>
        )}

        {enviado &&
          <div className="alert alert-success" role="alert">
            Denuncia enviada correctamente
          </div>
        }
        <div className="text-center mt-4">
          <SegButton
            label={isLoading ? "Enviando denuncia" : "Enviar denuncia"}
            type="submit"
            disabled={!formState.isValid || enviado}
            loading={isLoading}
            bgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
            hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
            hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
            hoverOpacity={config.origen !== "SEGURARSE"}
          />
        </div>
      </div>
    </form>
  );
};
