import React, { useRef, useState } from 'react'
import { useSiniestroStore } from '../../../../../Services/Providers/SiniestroStore';
import { SegInput } from '../../../../../Components/SegUI/SegInput/SegInput';
import { SegButton } from '../../../../../Components/SegUI/SegButton/SegButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface Tercero {
    nombreApellido: string;
    patente: string;
    compania: string;
    danios: string;
}


export const ModalTercerosInvolucrados = () => {

    const [showForm, setShowForm] = useState(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [formData, setFormData] = useState<Tercero>({
        nombreApellido: '',
        patente: '',
        compania: '',
        danios: ''
    });
    const [errors, setErrors] = useState<Record<string, string>>({});
    const btnCloseRef: any = useRef();

    const { siniestroValues, setSiniestroValues, addTercero, updateTercero, deleteTercero } = useSiniestroStore();
    const terceros = siniestroValues.terceros || [];

    const validateForm = () => {
        const newErrors: Record<string, string> = {};

        if (!formData.nombreApellido) newErrors.nombreApellido = "Este campo es requerido";
        if (!formData.patente) newErrors.patente = "Este campo es requerido";
        if (!formData.compania) newErrors.compania = "Este campo es requerido";
        if (!formData.danios) newErrors.danios = "Este campo es requerido";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (validateForm()) {
            const newSiniestroValues = {
                ...siniestroValues,
                terceros: editIndex !== null
                    ? terceros.map((t, i) => i === editIndex ? formData : t)
                    : [...terceros, formData]
            };
            setSiniestroValues(newSiniestroValues);

            // Reset form
            setFormData({
                nombreApellido: '',
                patente: '',
                compania: '',
                danios: ''
            });
            setShowForm(false);
            setEditIndex(null);
            btnCloseRef.current.click();
        }
    };

    const handleEdit = (index: number) => {
        setEditIndex(index);
        setFormData(terceros[index]);
        setShowForm(true);
    };

    const handleDelete = (index: number) => {
        const newSiniestroValues = {
            ...siniestroValues,
            terceros: terceros.filter((_, i) => i !== index)
        };
        setSiniestroValues(newSiniestroValues);
    };


    return (
        <div className="terceros-container">
            <h5 className="terceros-title">Terceros involucrados</h5>

            {terceros.length > 0 && (
                <div className="terceros-list">
                    {terceros.map((tercero, index) => (
                        <div key={index} className="tercero-card">
                            <div className="tercero-info">
                                <p><strong>Nombre:</strong> {tercero.nombreApellido}</p>
                                <p><strong>Patente:</strong> {tercero.patente}</p>
                                <p><strong>Compañía:</strong> {tercero.compania}</p>
                                <p><strong>Daños:</strong> {tercero.danios}</p>
                            </div>
                            <div className="tercero-actions">
                                <button type='button'
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasTerceros"
                                    onClick={() => handleEdit(index)}
                                    className="action-button edit">
                                    <EditIcon />
                                </button>
                                <button type='button' onClick={() => handleDelete(index)} className="action-button delete">
                                    <DeleteIcon />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <SegButton
                label="Agregar tercero involucrado"
                //onClick={() => setShowForm(true)}
                type="button"
                className="add-tercero-button"
                disabled={terceros.length >= 3}
                data_bs_toggle="offcanvas"
                data_bs_target="#offcanvasTerceros"
            />

            <div className="offcanvas offcanvas-bottom"
                tabIndex={-1} id="offcanvasTerceros" aria-labelledby="offcanvasBottomLabel"
                style={{
                    minHeight: (window.innerWidth > 778 && window.innerHeight > 778) ? "90vh" : "90vh",
                    backgroundColor: window.innerWidth > 778 ? "transparent" : "",
                    borderTop: window.innerWidth > 778 ? "0 solid white" : ""
                }} >
                <div className="offcanvas-body ">
                    <form onSubmit={handleSubmit}>
                        {
                            window.innerWidth < 778 &&
                            <div style={{ position: "relative", display: "flex", justifyContent: "end" }}>
                                <button type="button"
                                    className="btn-close text-reset p-2 bg-white"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                //style={{ position: "absolute",}}
                                ></button>
                            </div>
                        }
                        <div className={`px-20 pb-40 pb-lg-60 mx-auto ${window.innerWidth > 778 && " w-50"}`} style={{ position: "relative", marginTop: window.innerWidth < 778 ? "-16px" : "" }}>
                            <div className={window.innerWidth > 768 ? "p-4" : "mt-4"} style={{ background: "#fff", borderRadius: "8px" }}>
                                <SegInput
                                    label="Nombre y apellido"
                                    value={formData.nombreApellido}
                                    onChange={(e) => setFormData({ ...formData, nombreApellido: e.target.value })}
                                    width={100}
                                    type="text"
                                    name="nombreApellido"
                                    leyendaError={errors.nombreApellido}
                                    className='mb-3'
                                />

                                <SegInput
                                    label="Patente del vehículo"
                                    value={formData.patente}
                                    onChange={(e) => setFormData({ ...formData, patente: e.target.value })}
                                    width={100}
                                    type="text"
                                    name="patente"
                                    leyendaError={errors.patente}
                                    className='mb-3'
                                />

                                <SegInput
                                    label="Compañía aseguradora"
                                    value={formData.compania}
                                    onChange={(e) => setFormData({ ...formData, compania: e.target.value })}
                                    width={100}
                                    type="text"
                                    name="compania"
                                    leyendaError={errors.compania}
                                    className='mb-3'
                                />

                                <SegInput
                                    label="Daños del vehículo"
                                    value={formData.danios}
                                    onChange={(e) => setFormData({ ...formData, danios: e.target.value })}
                                    width={100}
                                    type="text"
                                    name="danios"
                                    leyendaError={errors.danios}
                                    className='mb-3'
                                />

                                <div className={`mt-4 ${window.innerWidth > 778 && "d-flex gap-2"}`}>
                                    {/*<SegButton
                                        label="Guardar"
                                        //onClick={(e:any) => handleSubmit(e)}
                                        type="submit"
                                        className={`save-button ${window.innerWidth < 778 && "w-100 mb-2"}`}
                                        disabled={false}
                                    />*/}
                                    <button
                                        onClick={(e: any) => handleSubmit(e)}
                                        type="submit"
                                        className={`btn save-button ${window.innerWidth < 778 && "w-100 mb-2"}`}
                                        style={{minWidth: "172px"}}
                                    >
                                        Guardar
                                    </button>
                                    <SegButton
                                        label="Cancelar"
                                        ref={btnCloseRef}
                                        data_bs_dismiss="offcanvas"
                                        onClick={() => {
                                            setShowForm(false);
                                            setEditIndex(null);
                                            setFormData({
                                                nombreApellido: '',
                                                patente: '',
                                                compania: '',
                                                danios: ''
                                            });
                                            setErrors({});
                                        }}
                                        type="button"
                                        className={`cancel-button ${window.innerWidth < 778 && "w-100"}`}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
