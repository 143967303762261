import { useState } from 'react';
import { SegTextarea } from '../../../../../Components/SegUI/SegTextArea/SegTextArea';
import { SegButton } from '../../../../../Components/SegUI/SegButton/SegButton';
import styled from '@emotion/styled';
import { StepComponentProps } from '../../Siniestros';
import { SiniestroData, useSiniestroStore } from '../../../../../Services/Providers/SiniestroStore';
import { ModalTercerosInvolucrados } from './ModalTercerosInvolucrados';
import './styles.css';
import { ModalPersonasLesionadas } from './ModalPersonasLesionadas';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GuardarSiniestro } from '../../../../../Services/API/GuardarSiniestro';
import { guardarArchivoSiniestro } from '../../../../../Services/API/GuardarArchivoSiniestro';

const ToggleContainer = styled.div`
  margin: 2rem 0;
  
  .toggle-section {
    margin-bottom: 2rem;
    
    /* Estilo para el toggle */
    .seg-toggle-container {
      transform: scale(1.2);
      margin: 1rem 0;
    }
    
    /* Estilo para el contenido que se despliega */
    .toggle-content {
      margin-top: 1.5rem;
      padding: 1.5rem;
      background-color: #f8f9fa;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.05);
      
      h3 {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
        color: #2c3e50;
      }
      
      table {
        font-size: 0.95rem;
      }
    }
  }
`;

export const AccidenteTransito = ({ setSteps, config }: StepComponentProps) => {

    const { siniestroValues, setSiniestroValues } = useSiniestroStore();
    const { formState, handleSubmit, control, setValue, trigger, register, watch } = useForm({
        defaultValues: {
            relatoHechos: '',
            daniosVehiculo: '',
            huboTerceros: false,
            huboLesionados: false
        }
    });
    const [fileBase64Frente, setFileBase64Frente] = useState<{ name: string, base64: string, type: string }>();
    const [fileBase64Dorso, setFileBase64Dorso] = useState<{ name: string, base64: string, type: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [enviado, setEnviado] = useState<boolean>(false);
    const renderTitle = (): string => {
        switch (siniestroValues.tipoSiniestro) {
            case "AccidenteTransito": return "Accidente de tránsito"
            case "RoturaCristalesCerraduras": return "Rotura de cristales/cerraduras"
            case "RoboTotal": return "Robo total"
            case "RoboParcial": return "Robo parcial"
            case "DanioParcial": return "Daño parcial"
        }
        return ""
    }

    function convertirImagenABase64(e: any, frente: boolean) {
        const archivo = e.target.files[0];
        const lector = new FileReader();

        if (archivo) {
            lector.onloadend = function () {
                const base64String = (lector.result as string).split(',')[1];
                if (frente) {
                    setFileBase64Frente({
                        name: archivo.name,
                        base64: base64String,
                        type: archivo.type.split("/")[0]
                    })
                } else {
                    setFileBase64Dorso({
                        name: archivo.name,
                        base64: base64String,
                        type: archivo.type.split("/")[0]
                    })
                }
            };
            lector.readAsDataURL(archivo);
        }
    }

    const onSubmit: SubmitHandler<any> = async (data) => {
        setIsLoading(true);
        setSiniestroValues({
            ...siniestroValues,
            relatoHechos: data.relatoHechos,
            daniosVehiculo: data.daniosVehiculo,
            //registroFrente: fileBase64Frente ? fileBase64Frente.base64 : null,
            //registroDorso: fileBase64Dorso ? fileBase64Dorso.base64 : null,
            huboTerceros: data.huboTerceros,
            huboLesionados: data.huboLesionados
        });

        const resp = await GuardarSiniestro({
            ...siniestroValues,
            relatoHechos: data.relatoHechos,
            daniosVehiculo: data.daniosVehiculo,
            //registroFrente: fileBase64Frente ? fileBase64Frente.base64 : null,
            //registroDorso: fileBase64Dorso ? fileBase64Dorso.base64 : null,
            huboTerceros: data.huboTerceros,
            huboLesionados: data.huboLesionados,
            terceros: data.huboTerceros ? siniestroValues.terceros : [],
            personasLesionadas: data.huboLesionados ? siniestroValues.personasLesionadas : []
        } as SiniestroData);

        if (resp.caseId) {
            setErrorMessage("");
            if (fileBase64Frente) {
                await guardarArchivoSiniestro(resp.caseId, fileBase64Frente ? fileBase64Frente.base64 : "", fileBase64Frente?.type);
            }
            if (fileBase64Dorso) {
                await guardarArchivoSiniestro(resp.caseId, fileBase64Dorso ? fileBase64Dorso.base64 : "", fileBase64Dorso?.type);
            }
            setEnviado(true);
        } else {
            setErrorMessage("Ocurrio un error, intentalo otra vez.");
        }

        setIsLoading(false);

    }


    return (
        <form onSubmit={handleSubmit(onSubmit)} className="row justify-content-center pt-4 pb-4">
            <div className="col-md-8">
                <legend className='mb-4 fw-bold'>{renderTitle()}</legend>
                {errorMessage && (
                    <div className="alert alert-danger">{errorMessage}</div>
                )}
                <Controller
                    name="relatoHechos"
                    control={control}
                    rules={{ required: "El relato de los hechos es obligatorio" }}
                    render={({ field, fieldState }) => (
                        <SegTextarea
                            label="Relato de los hechos"
                            placeHolder="Describe lo más detallado posible cómo sucedió el accidente"
                            width={100}
                            name=""
                            className='mb-3'
                            onChange={(e) => {
                                field.onChange(e);
                                setValue("relatoHechos", e.target.value);
                                trigger("relatoHechos");
                            }}
                            value={field.value}
                            leyendaError={fieldState?.error?.message}
                            borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                            focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
                        />

                    )}
                />

                <Controller
                    name="daniosVehiculo"
                    control={control}
                    rules={{ required: "El relato de los hechos es obligatorio" }}
                    render={({ field, fieldState }) => (
                        <SegTextarea
                            label="Daños del vehículo"
                            placeHolder="Detalla los daños que sufrió tu vehículo"
                            width={100}
                            name=""
                            className='mb-3'
                            onChange={(e) => {
                                field.onChange(e);
                                setValue("daniosVehiculo", e.target.value);
                                trigger("daniosVehiculo");
                            }}
                            value={field.value}
                            leyendaError={fieldState?.error?.message}
                            borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                            focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
                        />

                    )}
                />
                <ToggleContainer>
                    <div className="toggle-section">
                        <div className="form-check form-switch form-check-reverse form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                id={"terceros-check"}
                                {...register("huboTerceros")}
                            />
                            <label className="form-check-label fs-16" htmlFor={"terceros-check"}>¿Hubo tercero involucrado?</label>
                        </div>
                        {(watch("huboTerceros")) && (
                            <div className="toggle-content mt-1">
                                <ModalTercerosInvolucrados />
                            </div>
                        )}
                    </div>

                    <div className="toggle-section">
                        <div className="form-check form-switch form-check-reverse form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                id={"lesionados-check"}
                                {...register("huboLesionados")}
                            />
                            <label className="form-check-label fs-16" htmlFor={"lesionados-check"}>¿Hubo personas lesionadas?</label>
                        </div>
                        {(watch("huboLesionados")) && (
                            <div className="toggle-content mt-1">
                                <ModalPersonasLesionadas />
                            </div>
                        )}
                    </div>
                </ToggleContainer>

                <div className="mb-3">
                    <label className="form-label">Registro de conducir (frente)</label>
                    <div className="custom-file-input">
                        <input
                            type="file"
                            className="form-control"
                            accept="image/*,application/pdf"
                            onChange={(e) => convertirImagenABase64(e, true)}
                        />
                        {fileBase64Frente && (
                            <small className="text-success">
                                Archivo seleccionado: {fileBase64Frente.name}
                            </small>
                        )}
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label">Registro de conducir (dorso)</label>
                    <div className="custom-file-input">
                        <input
                            type="file"
                            className="form-control"
                            accept="image/*,application/pdf"
                            onChange={(e) => convertirImagenABase64(e, false)}
                        />
                        {fileBase64Dorso && (
                            <small className="text-success">
                                Archivo seleccionado: {fileBase64Dorso.name}
                            </small>
                        )}
                    </div>
                </div>

                {enviado &&
                    <div className="alert alert-success" role="alert">
                        Denuncia enviada correctamente
                    </div>
                }
                <div className="text-center mt-4">
                    <SegButton
                        label={isLoading ? "Enviando denuncia": "Enviar denuncia"}
                        type="submit"
                        disabled={!formState.isValid || enviado}
                        loading={isLoading}
                        bgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
                        hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
                        hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                        hoverOpacity={config.origen !== "SEGURARSE"}
                    />
                </div>
            </div>
        </form>
    );
};
