import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import { IPasos } from "../../../../Pages/Moto/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import obtenerModelos from "../../../../Services/API/Modelos";

type FormValues = {
    setModelo: SegSelectOption;
};

interface ModeloProps {
    config: EmpresaProps;
    setSteps: Dispatch<SetStateAction<IPasos>>
}

export const Modelo = ({ config, setSteps, ...rest }: ModeloProps) => {

    const [modeloSelected, setModeloSelected] = useState<SegSelectOption>()
    const [modelos, setModelos] = useState<SegSelectOption[]>();
    const nav = useNavigate();
    const methods = useForm();
    const { compania } = useParams();
    const { handleSubmit } = useForm<FormValues>();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

    useEffect(() => {
        //setLoading(true)
        const fetchData = async () => {
            const response: any = await obtenerModelos(cotizacionValues?.marca, "moto");
            const formattedData: SegSelectOption[] = response.map((item: any) => ({
                value: item,
                label: item,
            }));
            setModelos(formattedData);
        };
        fetchData().then(() => {
            //setLoading(false)
            if (cotizacionValues?.modelo) {
                setModeloSelected(cotizacionValues.modelo)
            }
        })
    }, [])

    useEffect(() => {

        !cotizacionValues?.marca && (compania ? nav("/moto/cotizacion/" + compania + "/marca") : nav("/moto/cotizacion/marca"))
    }, [])


    const onSubmit = (modeloSelecte: any) => {
        setCotizacionValues({
            ...cotizacionValues,
            modelo: modeloSelecte,
            version: modeloSelecte === cotizacionValues?.modelo ? cotizacionValues?.version : undefined,
            año: modeloSelecte === cotizacionValues?.modelo ? cotizacionValues?.año : undefined
        });
        compania ? nav("/moto/cotizacion/" + compania + "/anio") : nav("/moto/cotizacion/anio");
    };

    return (
        <div className="mt-4 bg-gray-light" {...rest}>
            <div className="frm01">
                <legend className="text-center">¿Qué modelo es?</legend>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex justify-content-center">
                        <div className="col-10 col-sm-8 col-md-5 col-lg-3">
                            <FormProvider {...methods}>
                                {modelos ? (
                                    <SegSelect
                                        options={modelos}
                                        width={"100%"}
                                        name="modelo"
                                        selected={modeloSelected}
                                        setSelected={setModeloSelected}
                                        handleSubmit={onSubmit}
                                        height={(window.innerHeight <= 700) ? "sm" : (window.innerHeight <= 910) ? "md" : "lg"}
                                        hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                                        focusBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                                        empresaColor={config.origen !== "SEGURARSE" ? {
                                            selected: config.colors.accent,
                                            hover: config.colors.tertiary
                                        } : undefined}
                                    />
                                ) :
                                    (<div className='d-flex justify-content-center'>
                                        <div className="spinner-border text-center" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>)}
                            </FormProvider>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}