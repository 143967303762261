import { useState } from 'react';
import { SegTextarea } from '../../../../../Components/SegUI/SegTextArea/SegTextArea';
import { SegButton } from '../../../../../Components/SegUI/SegButton/SegButton';
import { StepComponentProps } from '../../Siniestros';
import { SiniestroData, useSiniestroStore } from '../../../../../Services/Providers/SiniestroStore';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GuardarSiniestro } from '../../../../../Services/API/GuardarSiniestro';
import { guardarArchivoSiniestro } from '../../../../../Services/API/GuardarArchivoSiniestro';

export const RoboTotal = ({ setSteps, config }: StepComponentProps) => {
  const { siniestroValues, setSiniestroValues } = useSiniestroStore();
  const { formState, handleSubmit, control, setValue, trigger } = useForm({
    defaultValues: {
      relato: ""
    }
  });

  const [fileBase64, setFileBase64] = useState<{ name: string, base64: string, type: string }>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enviado, setEnviado] = useState<boolean>(false);

  const renderTitle = (): string => {
    switch (siniestroValues.tipoSiniestro) {
      case "AccidenteTransito": return "Accidente de tránsito"
      case "RoturaCristalesCerraduras": return "Rotura de cristales/cerraduras"
      case "RoboTotal": return "Robo total"
      case "RoboParcial": return "Robo parcial"
      case "DanioParcial": return "Daño parcial"
    }
    return ""
  }

  function convertirImagenABase64(e: any) {
    const archivo = e.target.files[0]; // Obtiene el archivo seleccionado

    const lector = new FileReader();

    if (archivo) {
      lector.onloadend = function () {
        const base64String = (lector.result as string).split(',')[1];
        setFileBase64({
          name: archivo.name,
          base64: base64String,
          type: archivo.type.split("/")[0]
        })
      };

      lector.readAsDataURL(archivo);
    }
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsLoading(true);

    setSiniestroValues({
      tipoSiniestro: "RoboTotal",
      relatoHechos: data.relato,
      denunciaPolicial: fileBase64 ? fileBase64.base64 : null
    });

    const resp = await GuardarSiniestro({
      ...siniestroValues,
      tipoSiniestro: "RoboTotal",
      relatoHechos: data.relato,
      denunciaPolicial: fileBase64 ? fileBase64.base64 : null
    } as SiniestroData);

    if (resp.caseId) {
      setErrorMessage("");
      if (fileBase64) {
        await guardarArchivoSiniestro(resp.caseId, fileBase64 ? fileBase64.base64 : "", fileBase64?.type);
      }
      setEnviado(true);
    } else {
      setErrorMessage("Ocurrio un error, intentalo otra vez.");
    }
    setIsLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row justify-content-center pt-4 pb-4">
      <div className="col-md-8">

        <legend className='mb-4 fw-bold'>{renderTitle()}</legend>

        <div className="alert alert-info mb-4">
          <p>Es imprescindible que efectúes la denuncia policial y nos envíes una copia a <a href="mailto:siniestros@segurarse.com.ar">siniestros@segurarse.com.ar</a></p>
        </div>

        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}

        <Controller
          name="relato"
          control={control}
          rules={{ required: "El relato del hecho es obligatorio" }}
          render={({ field, fieldState }) => (
            <>
              <SegTextarea
                label="Relato de los hechos"
                placeHolder="Describe lo más detallado posible cómo sucedió el robo"
                width={100}
                className='mb-3'
                onChange={(e) => {
                  field.onChange(e);
                  setValue("relato", e.target.value);
                  trigger("relato");
                }}
                value={field.value}
                name=''
                leyendaError={fieldState?.error?.message}
                borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
              />
              <span></span>
            </>
          )}
        />

        <div className="mb-3">
          <label className="form-label">Denuncia policial</label>
          <div className="custom-file-input">
            <input
              type="file"
              className="form-control"
              accept="image/*,application/pdf"
              onChange={convertirImagenABase64}
            />
            {fileBase64 && (
              <small className="text-success">
                Archivo seleccionado: {fileBase64.name}
              </small>
            )}
          </div>
        </div>
        {enviado &&
          <div className="alert alert-success" role="alert">
            Denuncia enviada correctamente
          </div>
        }
        <div className="text-center mt-4">
          <SegButton
            label={isLoading ? "Enviando denuncia" : "Enviar denuncia"}
            type="submit"
            disabled={!formState.isValid || enviado}
            loading={isLoading}
            bgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
            hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
            hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
            hoverOpacity={config.origen !== "SEGURARSE"}
          />
        </div>
      </div>
    </form>
  );
};
