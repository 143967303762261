import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CotizacionDesc } from "../../../../Pages/Auto/Precios";
import { SegTooltip } from "../../../SegUI/SegTooltip/SegTooltip";
import { useGraciasStore } from "../../../../Services/Providers/GraciasStore";
import { ModalDescripcion } from "./ModalDescripcion";
import { OffCanvas } from "./OffCanvas";
import { useEmisionStore } from "../../../../Services/Providers/EmisionStore";
import { useConfiguracionEmpresa } from "../../../../Services/Providers/useConfiguracionEmpresa";
import { getPathName } from "../../../../Services/API/helper";
import { updateEmisionStep } from "../../../../Services/API/updateEmisionStep";

interface ModalPreciosProps {
    cotizacionDesc: CotizacionDesc
    isEmision?: boolean
    rubro?: string;
}

export const ModalPrecios = ({ cotizacionDesc, isEmision = false, rubro = "Auto" }: ModalPreciosProps) => {
    const { setGraciasValues } = useGraciasStore();
    const { emisionValues } = useEmisionStore();
    const { compania } = useParams();
    const [indiceAbierto, setIndiceAbierto] = useState<number | null>(null);
    const { origen, config } = useConfiguracionEmpresa();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIndiceAbierto(-1)
    }, [cotizacionDesc]);


    const handleClick = (indice: number) => {
        setIndiceAbierto(prevIndice => (prevIndice === indice ? null : indice));
    };

    const handleSubmit = async (e: any, direccion: string, gracias: boolean = false) => {
        e.preventDefault();
        document.body.style.cssText = "";

        setIsLoading(true)
        await updateEmisionStep(0, {
            idItemCotizacion: emisionValues.cotizacionDesc!.id,
        } as any)

        setIsLoading(false)
        if (gracias) {
            setGraciasValues(cotizacionDesc?.companyName)
        }
        navigate(direccion);
    }

    return (
        <div>
            <OffCanvas abrirPrimeraCarga={emisionValues.cotizacionDesc ? true : false} className={isEmision === false ? "offcanvas offcanvas-end oc-cob" : "offcanvas offcanvas-end oc-cob oc-cob1"}>
                {isEmision === false ? <div className="oc-sum ">
                    <div className="cobertura">{cotizacionDesc?.planName}</div>
                    <div className="empresa">
                        <img src={cotizacionDesc?.companyLogo} alt={cotizacionDesc?.companyName} loading="lazy" />
                    </div>
                    <div className="descuento">
                        <span className="antes">{"$" + cotizacionDesc?.discountAmount}</span>
                        {/* <span className="ahora">{"-" + discountPerc + "%"}</span> */}
                    </div>
                    <div className="precio">
                        <span className="monto">{"$" + cotizacionDesc?.amountMonth}</span>
                        <span className="periodo"> /mes</span>
                    </div>
                    {!isEmision && (
                        <div className="cta">
                            {(cotizacionDesc?.id === null || origen !== "SEGURARSE") ?
                                rubro === "Moto" ?
                                    <a href={compania ? ("/moto/gracias/" + compania) : "/moto/gracias/"}
                                        type="button"
                                        onClick={(e) =>
                                            handleSubmit(e, (compania ? ("/moto/gracias/" + compania) : "/moto/gracias/"), true)
                                        }
                                        className="btn btn-primary"
                                        style={origen !== "SEGURARSE" ? { backgroundColor: config.colors.primary } : {}}>
                                        <span>Contratar</span>
                                        {isLoading &&
                                            <div className="spinner-border spinner-border-sm " role="status">
                                                <span className="sr-only" />
                                            </div>
                                        }
                                    </a>
                                    :
                                    <a href={compania ? ("/auto/gracias/" + compania) : "/auto/gracias/"}
                                        type="button"
                                        onClick={(e) =>
                                            handleSubmit(e, (compania ? ("/auto/gracias/" + compania) : "/auto/gracias/"), true)
                                        }
                                        className="btn btn-primary"
                                        style={origen !== "SEGURARSE" ? { backgroundColor: config.colors.primary } : {}}>
                                        <span>Contratar</span>
                                        {isLoading &&
                                            <div className="spinner-border spinner-border-sm " role="status">
                                                <span className="sr-only" />
                                            </div>
                                        }
                                    </a>
                                :
                                <div>
                                    {process.env.REACT_APP_EMISION_TEST === "true" ?
                                        <a
                                            href="/auto/emision/revision"
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => handleSubmit(e, "/auto/emision/revision")}
                                            style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}
                                        >
                                            <span>Contratar</span>
                                            {isLoading &&
                                                <div className="spinner-border spinner-border-sm " role="status">
                                                    <span className="sr-only" />
                                                </div>
                                            }
                                        </a> : <a
                                            href={`${getPathName() + process.env.REACT_APP_URL_EMISION}${cotizacionDesc?.companyName}/${cotizacionDesc?.id}${process.env.REACT_APP_URL_PREFIX}`} className="btn btn-primary" >
                                            Contratar
                                        </a>}
                                </div>
                            }
                        </div>
                    )}
                    {
                        origen === "SEGURARSE" &&
                        <div className="cta-wa d-none d-lg-block">
                            <p>¿Tenés dudas sobre esta cobertura?</p>
                            <a href={encodeURI("https://wa.me/5491169568002?text=¡Hola! Me interesa saber más de la cobertura " + (cotizacionDesc?.planName! || null) + " de la Aseguradora " + (cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer'><span>Consultanos</span> <svg className="icon"><use xlinkHref="#whatsapp" /></svg></a>
                        </div>
                    }

                    <div className="datafiscal d-none d-lg-flex">
                        <div><img src={cotizacionDesc?.fiscalInfoImg} width="30" alt='Información fiscal' /></div>
                        <p>Segurarse Asesores de Seguros S.A., Matrícula registrada 1174, expedida por la Superintendencia de Seguros de la Nación (www.ssn.gov.ar 4338-4000 o 0800-666-8400)</p>
                    </div>
                </div> : ""}
                <div className={isEmision === false ? "oc-det" : "oc-det oc-det1"}>


                    <div className="suma">
                        La cobertura incluye <br /><span style={{ color: origen !== "" ? config.colors.tertiary : "" }}>Suma asegurada: ${cotizacionDesc?.sumaAsegurada}</span>
                        <SegTooltip text="Es el monto máximo de indemnización. En caso de un siniestro total (por ejemplo, ante el robo del vehículo).​" placement="bottom-end" />
                    </div>
                    <div className="accordion accordion-flush acc-01" id="accDetalle">
                        {cotizacionDesc?.descList && cotizacionDesc?.descList.map((desc, index) =>
                            <ModalDescripcion
                                key={index}
                                nombre={desc.Nombre}
                                descripcion={desc.Descripcion}
                                isOpen={index === indiceAbierto}
                                toggleDesplegable={() => handleClick(index)}
                            />
                        )}
                    </div>
                    {
                        origen === "SEGURARSE" &&
                        <div className="cta-wa d-lg-none">
                            <p>¿Tenés dudas sobre esta cobertura?</p>
                            <a href={encodeURI("https://wa.me/5491169568002?text=¡Hola! Me interesa saber más de la cobertura " + (cotizacionDesc?.planName! || null) + " de la Aseguradora " + (cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer'><span>Consultanos</span> <svg className="icon"><use xlinkHref="#whatsapp" /></svg></a>
                        </div>
                    }
                    <div className="datafiscal d-lg-none">
                        <div><img src={cotizacionDesc?.fiscalInfoImg} width="30" alt="Información fiscal" /></div>
                        <p>Segurarse Asesores de Seguros S.A., Matrícula registrada 1174, expedida por la Superintendencia de Seguros de la Nación (www.ssn.gov.ar 4338-4000 o 0800-666-8400)</p>
                    </div>
                </div>

            </OffCanvas>
        </div>
    );
}

export default ModalPrecios;
