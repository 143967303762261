import { Dispatch, SetStateAction, useEffect } from "react";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";
import { IPasos } from "../../../../Pages/Moto/Cotizador";

type FormValues = {
  setApellido: string;
};

interface ApellidoProps {
  config: EmpresaProps;
  setSteps: Dispatch<SetStateAction<IPasos>>;
  apellidoValue: string | undefined;
}

export const Apellido = ({ config, setSteps, apellidoValue }: ApellidoProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { handleSubmit, control, setValue, formState, trigger } =
    useForm<FormValues>();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setApellido } = values;
    setCotizacionValues({
      ...cotizacionValues,
      apellido: setApellido.trim(),
    });
    compania
      ? nav("/moto/cotizacion/" + compania + "/email")
      : nav("/moto/cotizacion/email");
  };


  useEffect(() => {
    !cotizacionValues.nombre && (compania ? nav("/moto/cotizacion/" + compania + "/nombre") : nav("/moto/cotizacion/nombre"))
  }, [])


  useEffect(() => {
    setValue("setApellido", apellidoValue ? apellidoValue : ""); 
    apellidoValue && trigger();
  }, []);

  return (
    <div className="mt-4 bg-gray-light text-center">
      <div className="frm01">
        <legend>¿Cuál es tu apellido?</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mw-sm-450 m-auto d-flex justify-content-center">
            <Controller
              name="setApellido"
              control={control}
              rules={{
                validate: {
                  required: (value) => !!value || "Ingresá el apellido",
                  pattern: (value) =>
                    /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                    "Apellido inválido",
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput
                  
                  className="mt-3"
                  label=""
                  name="setApellido"
                  type="text"
                  width={92}
                  placeHolder="Ej: López"
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("setApellido", e.target.value); // Actualiza el valor en React Hook Form
                    trigger("setApellido");
                  }}
                  onKeyDown={(event) => {
                    if (event.key === " " && field.value.length === 0) {
                      event.preventDefault();
                    }
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setApellido
                      ? "var(--bs-form-invalid-border-color)"
                      : config.origen !== "SEGURARSE" ? config.colors.accent : ""
                    }`}
                  focusBorderColor={`${formState.errors.setApellido
                      ? "var(--bs-form-invalid-border-color)"
                      : config.origen !== "SEGURARSE" ? config.colors.accent : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={
                !formState.isValid // Verifica si el formulario es válido
              }
              bgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
              hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
              hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent: undefined}
              hoverOpacity={config.origen !== "SEGURARSE"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
