import { useState } from 'react';
import styled from '@emotion/styled';
import { SegTextarea } from '../../../../../Components/SegUI/SegTextArea/SegTextArea';
import { SegButton } from '../../../../../Components/SegUI/SegButton/SegButton';
import { StepComponentProps } from '../../Siniestros';
import { SiniestroData, useSiniestroStore } from '../../../../../Services/Providers/SiniestroStore';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GuardarSiniestro } from '../../../../../Services/API/GuardarSiniestro';
import { guardarArchivoSiniestro } from '../../../../../Services/API/GuardarArchivoSiniestro';

const CheckboxContainer = styled.div`
  margin-bottom: 1rem;
`;

const CustomCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 4px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ span {
    background-color: #007bff;
  }

  input:checked ~ span:after {
    display: block;
  }
`;

export const RoboParcial = ({ setSteps, config }: StepComponentProps) => {

  const { siniestroValues, setSiniestroValues } = useSiniestroStore();
  const { formState, handleSubmit, control, setValue, trigger, register, watch } = useForm({
    defaultValues: {
      relatoHechos: '',
      ruedas: {
        delanteraDerecha: false,
        delanteraIzquierda: false,
        traseraDerecha: false,
        traseraIzquierda: false,
        auxilio: false
      },
      bateria: false,
    }
  });

  const [fileBase64, setFileBase64] = useState<{ name: string, base64: string, type: string }>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enviado, setEnviado] = useState<boolean>(false);


  const renderTitle = (): string => {
    switch (siniestroValues.tipoSiniestro) {
      case "AccidenteTransito": return "Accidente de tránsito"
      case "RoturaCristalesCerraduras": return "Rotura de cristales/cerraduras"
      case "RoboTotal": return "Robo total"
      case "RoboParcial": return "Robo parcial"
      case "DanioParcial": return "Daño parcial"
    }
    return ""
  }

  function convertirImagenABase64(e: any) {
    const archivo = e.target.files[0]; // Obtiene el archivo seleccionado

    const lector = new FileReader();

    if (archivo) {
      lector.onloadend = function () {
        const base64String = (lector.result as string).split(',')[1];
        setFileBase64({
          name: archivo.name,
          base64: base64String,
          type: archivo.type.split('/')[0]
        })
      };

      lector.readAsDataURL(archivo);
    }
  }


  const onSubmit: SubmitHandler<any> = async (data) => {

    setIsLoading(true);
    debugger
    setSiniestroValues({
      ...siniestroValues,
      tipoSiniestro: "RoboParcial",
      relatoHechos: data.relatoHechos,
      ruedasRobo: data.ruedas,
      bateriaRobo: data.bateria,
      //denunciaPolicial: fileBase64 ? fileBase64.base64 : null,
    });

    const resp = await GuardarSiniestro({
      ...siniestroValues,
      tipoSiniestro: "RoboParcial",
      relatoHechos: data.relatoHechos,
      ruedasRobo: data.ruedas,
      bateriaRobo: data.bateria,
    } as SiniestroData);

    if (resp.caseId) {
      setErrorMessage("")
      if (fileBase64) {
        await guardarArchivoSiniestro(resp.caseId, fileBase64 ? fileBase64.base64 : "", fileBase64?.type);
      }
      setEnviado(true);
    } else {
      setErrorMessage("Ocurrio un error, intentalo otra vez.");
    }
    setIsLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row justify-content-center pt-4 pb-4">
      <div className="col-md-8">
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}

        <legend className='mb-4 fw-bold'>{renderTitle()}</legend>

        <Controller
          name="relatoHechos"
          control={control}
          rules={{ required: "El relato de los hechos es obligatorio" }}
          render={({ field, fieldState }) => (
            <SegTextarea
              label="Relato de los hechos"
              placeHolder="Describe lo más detallado posible cómo sucedió el robo parcial"
              width={100}
              name=""
              className='mb-3'
              onChange={(e) => {
                field.onChange(e);
                setValue("relatoHechos", e.target.value);
                trigger("relatoHechos");
              }}
              value={field.value}
              leyendaError={fieldState?.error?.message}
              borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
              focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
            />

          )}
        />

        <div className="card p-3 mb-3">
          <h5>Ruedas robadas</h5>
          <Controller
            name="ruedas"
            control={control}
            rules={{
              validate: (value) =>
                Object.values(value || {}).some((checked) => checked)
                  ? true
                  : 
                  watch("bateria") ? true :
                  "Debes seleccionar al menos una opción.",
            }}
            render={({ field: { value = {}, onChange } }) => (
              <>
                <CustomCheckbox>
                  Delantera derecha
                  <input
                    type="checkbox"
                    checked={value.delanteraDerecha || false}
                    onChange={(e) =>
                      onChange({ ...value, delanteraDerecha: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Delantera izquierda
                  <input
                    type="checkbox"
                    checked={value.delanteraIzquierda || false}
                    onChange={(e) =>
                      onChange({ ...value, delanteraIzquierda: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Trasera derecha
                  <input
                    type="checkbox"
                    checked={value.traseraDerecha || false}
                    onChange={(e) =>
                      onChange({ ...value, traseraDerecha: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Trasera Izquierda
                  <input
                    type="checkbox"
                    checked={value.traseraIzquierda || false}
                    onChange={(e) =>
                      onChange({ ...value, traseraIzquierda: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Auxilio
                  <input
                    type="checkbox"
                    checked={value.auxilio || false}
                    onChange={(e) =>
                      onChange({ ...value, auxilio: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>
              </>
            )}
          />
          {formState.errors.ruedas && <p style={{ color: "red" }}>{formState.errors.ruedas.message}</p>}


        </div>

        <div className="card p-3 mb-3">
          <CustomCheckbox>
            Batería
            <input
              type="checkbox"
              {...register("bateria")}
            />
            <span className="checkmark"></span>
          </CustomCheckbox>

          {(watch("bateria")) && (
            <div className="alert alert-info mt-3">
              <p>En las próximas 48hs hábiles nos contactaremos para solicitar:</p>
              <ul>
                <li>Comprobante de CBU</li>
                <li>Factura de compra de la batería nueva</li>
                <li>Copia de la denuncia policial</li>
              </ul>
            </div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Denuncia policial</label>
          <div className="custom-file-input">
            <input
              type="file"
              className="form-control"
              accept="image/*,application/pdf"
              onChange={convertirImagenABase64}
            />
            {fileBase64 && (
              <small className="text-success">
                Archivo seleccionado: {fileBase64.name}
              </small>
            )}
          </div>
        </div>
        {enviado &&
          <div className="alert alert-success" role="alert">
            Denuncia enviada correctamente
          </div>
        }
        <div className="text-center mt-4">
          <SegButton
            label={isLoading ? "Enviando denuncia" : "Enviar denuncia"}
            type="submit"
            disabled={!formState.isValid || enviado}
            loading={isLoading}
            bgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
            hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
            hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
            hoverOpacity={config.origen !== "SEGURARSE"}
          />
        </div>
      </div>
    </form>
  );
};