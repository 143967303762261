import Axios from "axios";
import { getPathName } from "./helper";

export const guardarArchivoSiniestro = async (idSiniestro: string, file: string, type: string = "image") => {
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }

    const params = {
        hash: process.env.REACT_APP_HASH,
        mediaFiles: {
            Id: idSiniestro,
            Data: file,
            Entidad: "Services",
            Tipo: type === "image" ? "imagen" : "archivo"
        }
    };

    const axiosPostRequest = async () => {
        return Axios.post( getPathName() + "/SetMediaFormulariosSiniestroVehiculosJSON", params, {
            headers: headers
        });
    };

    try {
        const response = await axiosPostRequest();
        return response;
    } catch (error: any) {
        console.error('Fallo de hacer varios intentos: ' + error.message);
        return error;
    }
}