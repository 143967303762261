import Axios from 'axios'
import { getPathName } from './helper';

const obtenerDatosPoliza = async (form: string, origen = "WEB") => {
    /*form = "dNAzhsuMjKXNAPyXPDQMU8ULY0xyJJ%2b4bWd4lAIdbfLJILgjxEIxkx%2fApL33BD9JzUqbag2AI1GFD7pi8bHss3fo%2bjmdcGUOxbaK8Vdj4JqIQs%2fPBcou8AbxuF2jpr3r"*/
    /*form = 
    "dNAzhsuMjKXNAPyXPDQMU8ULY0xyJJ+4bWd4lAIdbfLJILgjxEIxkx/ApL33BD9JzUqbag2AI1GFD7pi8bHss3fo+jmdcGUOxbaK8Vdj4JqIQs/PBcou8AbxuF2jpr3r"
    form = 
    "dNAzhsuMjKXNAPyXPDQMU8ULY0xyJJ%2b4bWd4lAIdbfLJILgjxEIxkx%2fApL33BD9JzUqbag2AI1GFD7pi8bHss3fo%2bjmdcGUOxbaK8Vdj4JqIQs%2fPBcou8AbxuF2jpr3r"
    console.log(form);*/

    
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    const {status, data} = await Axios.get( getPathName() + `/GetFormulariosJSON?hash=${process.env.REACT_APP_HASH}&form=${form}&origen=${origen}`, {
        headers
    });

    return data.api_data
};

export default obtenerDatosPoliza;

/*"dNAzhsuMjKXNAPyXPDQMU8ULY0xyJJ 2b4bWd4lAIdbfLJILgjxEIxkx/ApL33BD9JzUqbag2AI1GFD7pi8bHss3fo+jmdcGUOxbaK8Vdj4JqIQs/PBcou8AbxuF2jpr3r"
"dNAzhsuMjKXNAPyXPDQMU8ULY0xyJJ+4bWd4lAIdbfLJILgjxEIxkx/ApL33BD9JzUqbag2AI1GFD7pi8bHss3fo+jmdcGUOxbaK8Vdj4JqIQs/PBcou8AbxuF2jpr3r"
"dNAzhsuMjKXNAPyXPDQMU8ULY0xyJJ 4bWd4lAIdbfLJILgjxEIxkx/ApL33BD9JzUqbag2AI1GFD7pi8bHss3fo jmdcGUOxbaK8Vdj4JqIQs/PBcou8AbxuF2jpr3r"
"dNAzhsuMjKXNAPyXPDQMU8ULY0xyJJ+4bWd4lAIdbfLJILgjxEIxkx/ApL33BD9JzUqbag2AI1GFD7pi8bHss3fo+jmdcGUOxbaK8Vdj4JqIQs/PBcou8AbxuF2jpr3r"
*/