import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import obtenerProvincias from "../../../../Services/API/Provincias";
import obtenerLocalidaXProvincia from "../../../../Services/API/Localidades";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { FormProvider, useForm } from "react-hook-form";
import { useEmisionStore } from "../../../../Services/Providers/EmisionStore";
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";
import { IPasos } from "../../../../Pages/Moto/Cotizador";

interface ICodigoPostalManual {
  provincia: string;
  localidad: string;
  cuandoContrata: string;
}

interface CodigoPostalManualProps {
  config: EmpresaProps;
  setSteps: Dispatch<SetStateAction<IPasos>>
}

export const CodigoPostalManual = ({ config, setSteps, ...rest }: CodigoPostalManualProps) => {
  const nav = useNavigate();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const { resetEmisionValues } = useEmisionStore();

  const [localidadDis, setLocalidadDis] = useState<boolean>(false);
  const [provinciaDis, setProvinciaDis] = useState<boolean>(false);

  const [localidadLoad, setLocalidadLoad] = useState<boolean>(false);
  const [provinciaLoad, setProvinciaLoad] = useState<boolean>(false);

  const [provincias, setProvincias] = useState<SegSelectOption[]>([]);
  const [localidades, setLocalidades] = useState<SegSelectOption[]>([]);

  const [provincia, setProvincia] = useState<SegSelectOption>();
  const [localidad, setLocalidad] = useState<SegSelectOption>();

  const [cuandoContrata, setCuandoContrata] = useState<SegSelectOption>();

  const [errorCaptcha, setErrorCaptcha] = useState("");

  const [openProvincia, setOpenProvincia] = useState<boolean>(false)
  const [openLocalidad, setOpenLocalidad] = useState<boolean>(false)
  const [openCuandoContrata, setOpenCuandoContrata] = useState<boolean>(false)

  const btnRef = useRef<HTMLButtonElement | null>(null);

  const opcionesCuandoContrata = [
    { value: "ahora", label: "Ahora" },
    { value: "7-dias", label: "En 7 días" },
    { value: "15-dias", label: "En 15 días" },
    { value: "no-se", label: "No lo sé" },
    { value: "no-quiero", label: "No quiero asegurar" },
  ];

  const {
    handleSubmit,
    setValue,
    setError,
    trigger,
    formState: { errors },
  } = useForm<ICodigoPostalManual>();

  const methods = useForm<ICodigoPostalManual>();
  const { compania } = useParams();
  const fetchProvincias = async () => {
    setProvinciaDis(true);
    setProvinciaLoad(true);
    const response: any = await obtenerProvincias();
    setProvinciaDis(false);
    setProvinciaLoad(false);
    const formattedData = response.map((item: any) => ({
      value: item,
      label: item,
    }));
    setProvincias(formattedData)
  }

  const handleLocalidadChange = (e: any) => {
    setLocalidad(e)
    setOpenCuandoContrata(true)
    setOpenLocalidad(false)
  }
  const handleProvinciaChange = (e: any) => {
    setProvincia(e)
    setOpenLocalidad(true)
    setOpenCuandoContrata(false)
  }


  const fetchLocalidades = async () => {
    if (provincia !== null && provincia !== undefined) {

      setLocalidadLoad(true);
      setLocalidadDis(true);
      const response: any = await obtenerLocalidaXProvincia(provincia?.value);
      setLocalidadDis(false);
      setLocalidadLoad(false);
      const formattedData = response.map((item: any) => ({
        value: item,
        label: item,
      }));
      setLocalidades(formattedData);
      if (localidad) {
        if (
          !formattedData.some((loc: any) => {
            return loc.value === localidad?.value;
          })
        ) {
          setValue("localidad", "");
          setLocalidad({
            value: "",
            label: ""
          })
        } else {
          setLocalidad(cotizacionValues.localidad)
          setValue("localidad", localidad?.value);
        }
      }
      if (cotizacionValues?.localidad) {
        if (
          !formattedData.some((loc: any) => {
            return loc.value === cotizacionValues.localidad?.value;
          })
        ) {
          setValue("localidad", "");
          setLocalidad({
            value: "",
            label: ""
          })
        } else {
          setLocalidad(cotizacionValues?.localidad)
          setValue("localidad", cotizacionValues?.localidad?.value);
        }
      }

    }
  };

  useEffect(() => {
    fetchProvincias().then(() => {
      if (cotizacionValues?.provincia) {
        setProvincia(cotizacionValues?.provincia)
        setValue("provincia", cotizacionValues.provincia?.value);
        setOpenProvincia(false)
      } else {
        setOpenProvincia(true)
      }
    });
    if (cotizacionValues?.cuandoContrata) {
      setCuandoContrata(cotizacionValues?.cuandoContrata)
      setValue("cuandoContrata", cotizacionValues?.cuandoContrata.value)
    }

    if (cotizacionValues.provincia && cotizacionValues.localidad && cotizacionValues.cuandoContrata) {
      setOpenProvincia(false)
      setOpenLocalidad(false)
      setOpenCuandoContrata(false)
    }
  }, []);

  useEffect(() => {
    fetchLocalidades();
  }, [provincia]);

  const onSubmit = (data: ICodigoPostalManual) => {
    setErrorCaptcha("");
    const key = process.env.REACT_APP_RECAPTCHA_KEY
    grecaptcha.ready(function () {
      grecaptcha.execute(key!, { action: 'submit' }).then(function (token) {
        setCotizacionValues({
          ...cotizacionValues,
          localidad: localidad,
          provincia: provincia,
          cuandoContrata: cuandoContrata,
          captchaToken: token,
          cotizaciones: [],
        });
        resetEmisionValues()
        //AR 15-04-2024: Por el momento, eliminamos las páginas de resultado de aseguradoras ya que no existen en las campañas actuales
        cuandoContrata?.value === "ahora" ?
          (/*compania ? 
            nav("/auto/precios/" + compania + "?cuandoasegura=" + cuandoContrata?.value) : */
            nav("/resultado-del-cotizador-de-moto?cuandoasegura=" + cuandoContrata?.value)) : 
          (/*compania ? 
            nav("/auto/precios/" + compania) : */
            nav("/resultado-del-cotizador-de-moto"))
      });
    });
  };

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');

  useEffect(() => {
    !cotizacionValues.genero && (compania ? nav("/moto/cotizacion/" + compania + "/genero") : nav("/moto/cotizacion/genero"))
    
    if(error){
      if(error === "El Captcha no es válido"){
        setErrorCaptcha(error);
      }else if(error == "Error de conexion."){
        setErrorCaptcha("Se ha producido un error de conexión. Verificá que todo esté correcto e intentá nuevamente en 30 segundos.")
      }else{
        setError("provincia", {
          type: 'custom',
          message: error,
        });
      }
    }else{
      setValue("provincia", cotizacionValues.provincia?.value ? cotizacionValues.provincia?.value : "");
      cotizacionValues.provincia?.value && trigger()
    }
  }, [])

  return (
    <div className="mt-4 bg-gray-light" {...rest}>
      <div className="frm01">
        <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
          <div className="cod-postal-column justify-content-center gap-4">
            <div className="col-10 col-sm-10 col-md-5 col-lg-3">
              <fieldset>
                <legend>Seleccioná tu provincia</legend>
                <FormProvider {...methods}>
                  <SegSelect
                    selected={provincia}
                    setSelected={handleProvinciaChange}
                    name="provincia"
                    options={provincias}
                    width={"100%"}
                    height={"md"}
                    disabled={provinciaDis}
                    loading={provinciaLoad}
                    borderColor={errors.provincia ? "red" : ""}
                    openDefault={openProvincia}
                    hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                    focusBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                    empresaColor={config.origen !== "SEGURARSE" ? {
                      selected: config.colors.accent,
                      hover: config.colors.tertiary
                    } : undefined}
                  />
                </FormProvider>
                <p>{errors.provincia?.message}</p>
              </fieldset>
            </div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-3">
              <fieldset className="">
                <legend>Seleccioná tu localidad</legend>
                <FormProvider {...methods}>
                  <SegSelect
                    selected={localidad}
                    setSelected={handleLocalidadChange}
                    name="localidad"
                    options={localidades}
                    width={"100%"}
                    height={"md"}
                    disabled={localidadDis}
                    loading={localidadLoad}
                    borderColor={errors.localidad ? "red" : ""}
                    openDefault={openLocalidad}
                    hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                    focusBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                    empresaColor={config.origen !== "SEGURARSE" ? {
                      selected: config.colors.accent,
                      hover: config.colors.tertiary
                    } : undefined}
                  />
                </FormProvider>
                <p>{errors.localidad?.message}</p>
              </fieldset>
            </div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-3">
              <fieldset className="">
                <legend>¡Por último! ¿Cuándo asegurás?</legend>
                <FormProvider {...methods}>
                  <SegSelect
                    selected={cuandoContrata}
                    setSelected={setCuandoContrata}
                    name="cuandoContrata"
                    options={opcionesCuandoContrata}
                    width={"100%"}
                    height={window.innerHeight < 710  ? "sm" : "md"}
                    borderColor={errors.cuandoContrata ? "red" : ""}
                    openDefault={openCuandoContrata}
                    autoFoco={true}
                    hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                    focusBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                    empresaColor={config.origen !== "SEGURARSE" ? {
                      selected: config.colors.accent,
                      hover: config.colors.tertiary
                    } : undefined}
                  />
                </FormProvider>
                <p>{errors.localidad?.message}</p>
              </fieldset>
            </div>
          </div>
          <div className="form-buttons text-center" style={{marginTop: "30px", paddingBottom: "24px"}}>
            {errorCaptcha &&
              <div style={{ margin: "0 auto 20px auto" }} 
              className={`alert alert-danger ${window.innerHeight <= 700 ? "w-75" : "w-25"}`}
              role="alert">
                {errorCaptcha}
              </div>
            }
            <SegButton
              label="Cotizar"
              type="submit"
              disabled={localidad?.value && provincia?.value && cuandoContrata?.value ? false : true}
              ref={btnRef}
              bgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
              hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
              hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent: undefined}
              hoverOpacity={config.origen !== "SEGURARSE"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
