import Axios from 'axios'
import { formatDate, getPathName } from './helper';
import { PersonaLesionada, SiniestroData, TipoSiniestro, Tercero } from '../Providers/SiniestroStore';

interface TerceroInvolucrado {
    NombreApellido: string;
    PatenteVehiculo: string;
    CompaniaVehiculo: string;
    DaniosVehiculo: string;
}
interface Lesionado {
    NombreApellido: string;
    DetalleLesionesNivelGravedad: string;
    DNI: string;
    Telefono: string;
    DatosVehiculo: string;
}

interface AccidenteTransito {
    RelatoHechos: string;
    DaniosVehiculo: string;
    HuboTerceroInvolucrado: boolean;
    TercerosInvolucrados: TerceroInvolucrado[] | null;
    HuboPersonaLesionada: boolean;
    PersonasLesionadas: Lesionado[] | null;
    FrenteRegistroConducir: any | null;
    DorsoRegistroConducir: any | null;
    CedulaVehiculo: any | null;
}

interface CristalesCerraduras {
    CerraduraBaul: boolean;
    CerraduraDelanteraAcompaniante: boolean;
    CerraduraDelanteraConductor: boolean;
    CristalDelanteroAcompaniante: boolean;
    CristalDelanteroConductor: boolean;
    CristalLuneta: boolean;
    CristalOtro: any | null;
    CristalParabrisas: boolean;
    CristalTraseroAcompaniante: boolean;
    CristalTraseroConductor: boolean;
    RelatoHechos: string;
}

interface RoboTotal {
    RelatoHechos: string;
    DenunciaPolicial: string;
}

interface RoboParcial {
    RelatoHechos: string;
    DenunciaPolicialRoboParcial: string;
    Bateria: boolean;
    RuedaAuxilio: boolean;
    RuedaDelanteraAcompaniante: boolean;
    RuedaDelanteraConductor: boolean;
    RuedaTraseraAcompaniante: boolean;
    RuedaTraseraConductor: boolean;
}

interface DanioParcial {
    RelatoHechos: string;
    DenunciaIncendioDanioParcial: string;
    Granizo: boolean;
    Incendio: boolean;
    Inundacion: boolean;
    Parcial: boolean;
}

interface SiniestroParams {
    hash: string;
    TipoFormulario: "SiniestroAutoMoto",
    AccountId: string,
    PolizaId: string,
    RiesgoId: string,
    ProvinciaArgentina: string,
    LocalidadArgentina: string,
    SiniestroAutoMoto: {
        NumeroPoliza: string
        NombreApellido: string
        Compania: string
        Fecha: string
        Hora: string
        CalleDireccion: string
        NumeroDireccion: string
        Provincia: string
        Localidad: string
        Pais: string
        CodigoPostal: string
        TipoSiniestro: TipoSiniestro;
        AccidenteTransito?: AccidenteTransito;
        CristalesCerraduras?: CristalesCerraduras;
        RoboTotal?: RoboTotal;
        RoboParcial?: RoboParcial;
        DanioParcial?: DanioParcial;
    }
}


interface DataPoliza {
    AccountId: string;
    PolizaId: string;
    NumeroPoliza: string;
    NombreApellido: string;
    Compania: string;
    RiesgoId?: string;
}

const adapterPorTipoSiniestro = (tipo: TipoSiniestro, poliza: DataPoliza, data: SiniestroData): SiniestroParams => {
    const base: SiniestroParams = {
        hash: process.env.REACT_APP_HASH + "",
        TipoFormulario: "SiniestroAutoMoto",
        AccountId: poliza.AccountId,
        PolizaId: poliza.PolizaId,
        RiesgoId: poliza.RiesgoId || "",
        ProvinciaArgentina: data.provincia,
        LocalidadArgentina: data.localidad,
        SiniestroAutoMoto: {
            NumeroPoliza: poliza.NumeroPoliza,
            NombreApellido: poliza.NombreApellido,
            Compania: poliza.Compania,
            TipoSiniestro: tipo,
            Fecha: formatDate(new Date(data.fecha)),
            Hora: data.hora,
            CalleDireccion: data.calle,
            NumeroDireccion: data.numero,
            Provincia: data.provincia,
            Localidad: data.localidad,
            Pais: data.pais,
            CodigoPostal: data.codigoPostal,
        }
    }
    switch (tipo) {
        case "AccidenteTransito": {
            base.SiniestroAutoMoto.AccidenteTransito = {
                RelatoHechos: data.relatoHechos || "",
                DaniosVehiculo: data.daniosVehiculo || "",
                HuboTerceroInvolucrado: data.huboTerceros || false,
                TercerosInvolucrados: adapterTerceros(data.terceros),
                HuboPersonaLesionada: data.huboLesionados || false,
                PersonasLesionadas: adapterLesionados(data.personasLesionadas),
                FrenteRegistroConducir: null,
                DorsoRegistroConducir: null,
                CedulaVehiculo: null
            }
            break;
        }
        case "RoturaCristalesCerraduras": {
            base.SiniestroAutoMoto.CristalesCerraduras = {
                RelatoHechos: data.relatoHechos || "",
                CerraduraBaul: data.cerraduras?.baul || false,
                CerraduraDelanteraConductor: data.cerraduras?.delanteraConductor || false,
                CerraduraDelanteraAcompaniante: data.cerraduras?.delanteraAcompañante || false,
                CristalDelanteroConductor: data.cristales?.delanteroConductor || false,
                CristalDelanteroAcompaniante: data.cristales?.delanteroAcompañante || false,
                CristalTraseroConductor: data.cristales?.traseroConductor || false,
                CristalTraseroAcompaniante: data.cristales?.traseroAcompañante || false,
                CristalLuneta: data.cristales?.luneta || false,
                CristalParabrisas: data.cristales?.parabrisas || false,
                CristalOtro: false, //TODO: Agregar al form

            }
            break;
        }
        case "RoboTotal": {
            base.SiniestroAutoMoto.RoboTotal = {
                RelatoHechos: data.relatoHechos || "",
                DenunciaPolicial: ""
            }
            break;
        }
        case "RoboParcial": {
            base.SiniestroAutoMoto.RoboParcial = {
                RelatoHechos: data.relatoHechos || "",
                Bateria: data.bateriaRobo || false,
                RuedaAuxilio: data.ruedasRobo?.auxilio || false,
                RuedaDelanteraConductor: data.ruedasRobo?.delanteraIzquierda || false,
                RuedaDelanteraAcompaniante: data.ruedasRobo?.delanteraDerecha || false,
                RuedaTraseraConductor: data.ruedasRobo?.traseraIzquierda || false,
                RuedaTraseraAcompaniante: data.ruedasRobo?.traseraDerecha || false,
                DenunciaPolicialRoboParcial: ""
            }
            break;
        }
        case 'DanioParcial': {
            base.SiniestroAutoMoto.DanioParcial = {
                RelatoHechos: data.relatoHechos || "",
                Granizo: data.tiposDanio?.granizo || false,
                Inundacion: data.tiposDanio?.inundacion || false,
                Incendio: data.tiposDanio?.incendio || false,
                Parcial: false, //TODO: Agregar al form
                DenunciaIncendioDanioParcial: "",
            }
            break;
        }
    }

    return base;
}

const adapterLesionados = (lesionados: PersonaLesionada[] = []): Lesionado[] => {
    const arrLesionados: Lesionado[] = [];
    lesionados.forEach(lesionado => {
        arrLesionados.push({
            NombreApellido: lesionado.nombreApellido,
            DNI: lesionado.dni,
            Telefono: lesionado.telefono,
            DatosVehiculo: lesionado.vehiculo,
            DetalleLesionesNivelGravedad: lesionado.detalleLesiones
        })
    })
    return arrLesionados;
}

const adapterTerceros = (terceros: Tercero[] = []): TerceroInvolucrado[] => {
    const arrTerceros: TerceroInvolucrado[] = [];
    terceros.forEach(tercero => {
        arrTerceros.push({
            NombreApellido: tercero.nombreApellido,
            PatenteVehiculo: tercero.patente,
            CompaniaVehiculo: tercero.compania,
            DaniosVehiculo: tercero.danios
        })
    })
    return arrTerceros;
}


export const GuardarSiniestro = async (dataSinestro: SiniestroData) => {
    
    try {
        const params = adapterPorTipoSiniestro(dataSinestro.tipoSiniestro, dataSinestro.poliza as DataPoliza, dataSinestro)
        const headers = {
            'content-type': 'application/json',
            "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
        }

        const { data, status } = await Axios.post(getPathName() + "/SetFormularioSiniestroVehiculosJSON", params, {
            headers: headers
        });
        return data.api_data;
    } catch (error: any) {
        console.error('Fallo de hacer varios intentos: ' + error.message);
        return error;
    }
}