import Axios from 'axios'
import { getPathName } from './helper';

const obtenerVersiones = async (marca, modelo, anio, rubro = "auto") => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        marca: marca?.label,
        modelo: modelo?.label,
        anio: anio?.value,
        rubro
    }
    const headers = { 
        'content-type': 'application/json',
        "Authorization" : 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/getversionjson", params,
        {
            headers: headers
        }).then(function (response) {
            return response.data.versiones;
        }).catch(function (error) {
            return error;
    });
};

export default obtenerVersiones;