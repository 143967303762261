import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { imagesCompanias } from '../../../Assets/imagesCompaniasAsistenciaViajero'
import { Hero } from '../Components/Hero'
import { PreguntasFrecuentes } from '../Components/PreguntasFrecuentes'
import { CompaniasSlider } from '../Components/CompaniasSlider'
import useSEOConfig from "../../../hooks/seo/useSEOConfig"

export const AsistenciaAlViajero = () => {

    const preguntasFrecuentes = [
        {
            titulo: '¿Qué incluye una cobertura de Asistencia al Viajero?',
            subTitulo: `Las principales coberturas de la Asistencia al Viajero son; gastos médicos por accidente o enfermedad, evacuación médica y repatriación, cancelación o interrupción del viaje, pérdida o retraso de equipaje, asistencia legal, gastos por demora de viaje, responsabilidad civil, y asistencia para la reposición de documentos perdidos. Algunas pólizas incluyen salones VIP en aeropuertos, telemedicina, entre otros beneficios.`
        },
        {
            titulo: '¿Es obligatorio contratar una cobertura de Asistencia al Viajero?',
            subTitulo: `No siempre es obligatorio, pero en algunos países es un requisito para ingresar,
            especialmente en destinos con sistemas de salud costosos o durante situaciones
            excepcionales como pandemias. Incluso cuando no es obligatorio, es altamente
            recomendable para protegerte ante imprevistos.`
        },
        {
            titulo: '¿Cómo puedo gestionar un reintegro?',
            subTitulo: `Para gestionar un reintegro, debés comunicarte con la compañía contratada tan pronto ocurra el evento cubierto. Tené en cuenta que se requiere la presentación de documentación, como facturas médicas, informes de hospitalización, denuncia policial (en caso de robo), recibos de transporte, y cualquier otro comprobante necesario para justificar el reclamo. Es recomendable guardar copias de todos los documentos.`
        },
        {
            titulo: '¿Cómo afecta la práctica de deportes o actividades de aventura a la prestación de la cobertura?',
            subTitulo: `Algunas pólizas pueden cubrir accidentes o lesiones derivados de la práctica de deportes o actividades de aventura, mientras que otras pueden excluirlos o requerir un suplemento adicional. Si planeás participar en actividades de alto riesgo, es importante informar a la compañía y confirmar que estás cubierto.`
        }
    ];

    useSEOConfig(
        {
            title: "Seguros Personales y Profesionales - Asegurá tu tranquilidad",
            description: "Conocé la amplia variedad de seguros que tenemos para vos · Auto · Accidentes Personales · Moto · Hogar · Asistencia al viajero · Mala Praxis · Vida · Celular",
            keywords: "Seguros de Auto, Seguro para moto, Seguros económicos, Cotizar seguro de auto, Seguro Mala praxis, Seguro online, Asistencia al viajero, Aseguradoras, Seguros rivadavia, Seguro flota, Cotizar seguro",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros personales a tu medida al mejor precio. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
        }
    )

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Asistencia al viajero'
                    subTitulo={{
                        __html: `Para disfrutar del 100% cada uno de tus viajes, es fundamental que cuentes con la tranquilidad de estar protegido ante cualquier imprevisto. Aprovecha al máximo tu experiencia, y ¡buen viaje!<br /><br />¿Empezamos?`
                    }}
                    url='https://www.nowassistance.com/ar/es/cotizador?utm_source=web&utm_medium=CTAproducto&utm_campaign=SegurarseWeb'
                    icon="#icon-seg-viajero"
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué coberturas ofrecemos?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                            La cobertura de Asistencia al Viajero está diseñada para brindar protección y asistencia en caso de emergencias y situaciones imprevistas que puedan ocurrir durante un viaje, ya sea por turismo, trabajo, estudios u otros motivos.
                            </p>
                        </div>
                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Gastos médicos por accidente o enfermedad:</b> cubre los gastos médicos
                                            necesarios en caso de enfermedad o accidente durante el viaje. Esto incluye
                                            consultas médicas, hospitalización, intervenciones quirúrgicas, medicamentos
                                            recetados, y otros tratamientos necesarios. Dependiendo de la póliza, la cobertura
                                            puede variar en términos de límite máximo de gastos y duración del viaje.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Cobertura por cancelación o interrupción del viaje:</b> puede cubrir los costos no
                                            reembolsables de transporte, alojamiento y actividades en caso de que tengas que
                                            cancelar o interrumpir tu viaje debido a razones justificadas, como enfermedad,
                                            accidente, muerte de un familiar cercano, desastres naturales, entre otros motivos
                                            especificados en la póliza.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Compensación por pérdida o retraso de equipaje:</b>  cobertura por el costo de
                                            reemplazo de artículos personales en caso de que tu equipaje se pierda, sea
                                            robado, dañado o se retrase durante el viaje. También puede incluir un monto para
                                            la compra de artículos esenciales si tu equipaje se retrasa más allá de un período
                                            de tiempo específico.
                                        </li>

                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Gastos por demora de viaje:</b> compensación económica en caso de que tu vuelo u
                                            otro medio de transporte se retrase más allá de un tiempo determinado
                                            (generalmente más de 6 a 12 horas). Este beneficio suele cubrir los costos
                                            adicionales de alojamiento, comidas y transporte.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Telemedicina y asistencia médica telefónica:</b> Ofrece acceso a consultas médicas
                                            telefónicas o a través de plataformas digitales, lo que permite obtener orientación
                                            médica sin necesidad de acudir a un centro de salud local, especialmente útil en
                                            zonas remotas o con infraestructura médica limitada.
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="container mt-4">
                    <div className="row gx-lg-80">
                        <div className="col-10 col-md-8 col-xl-6 mx-auto text-center">
                            <p className="fs-18 fs-lg-20 lh-13 text-black mb-20 text-center">
                                Si tenés dudas, contás con nuestro equipo de especialistas para asesorarte en todo momento.
                            </p>
                            <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer" className="d-inline-flex align-items-center fs-18 fs-lg-20">
                                <span className="fs-18 fs-lg-20 text-black fw-bold">Chatéanos</span>
                                <svg className="svg-icon ms-2 w-12em">
                                    <use xlinkHref="#whatsapp" />
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>
                <div className="py-5">
                    <div className="container">
                        <div className="col-11 col-md-8 mx-auto mb-4 mb-lg-5">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center">
                                Somos un bróker, trabajamos con las mejores aseguradoras del país
                            </h2>
                        </div>
                        <CompaniasSlider
                            companias={imagesCompanias}
                        />
                    </div>
                </div>
                <PreguntasFrecuentes
                    faqs={preguntasFrecuentes}
                />
            </div>
        </>
    )
}
