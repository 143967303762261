import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { SegButton } from "../../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../../SegUI/SegInput/SegInput";
import { SegTooltip } from "../../../../SegUI/SegTooltip/SegTooltip";
import { IPasos } from "../../../../../Pages/Prepaga/Individuo/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useCotizacionStore } from "../../../../../Services/Providers/CotizacionStore";
import { SegToggle } from "../../../../SegUI/SegToggle/SegToggle";
import styled from "@emotion/styled";

type FormValues = {
  setDia: string;
  setMes: string;
  setAnio: string;
};

interface FechaNacProps {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  fechaNacValue: { dia: string; mes: string; año: string } | undefined;
}

const validateAge = (year: string, month: string, day: string): { ok: boolean; msg: string } => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Meses en JavaScript son 0-indexados
  const currentDay = new Date().getDate();

  const enteredYear = parseInt(year, 10);
  const enteredMonth = parseInt(month, 10);
  const enteredDay = parseInt(day, 10);

  if (((enteredYear + "").length > 3) && currentYear - enteredYear > 99) {
    return {
      ok: false,
      msg: "Sólo aseguramos a menores de 100 años."
    }
  }
  if (currentYear - enteredYear > 20) {
    return {
      ok: true,
      msg: ""
    }; // Mayor de 20 años
  } else if (currentYear - enteredYear === 20) {
    // Mismo año, verifica mes y día
    if (
      currentMonth > enteredMonth ||
      (currentMonth === enteredMonth && currentDay >= enteredDay)
    ) {
      return {
        ok: true,
        msg: ""
      }; // Mayor de 20 años
    }
  }
  return {
    ok: false,
    msg: "Sólo aseguramos a mayores de 20 años."
  };
};

const FormWrapper = styled.div`
  padding-left: 0;
  padding-right: 0;

  @media (max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const FechaNacimiento = ({
  setSteps,
  fechaNacValue,
  ...rest
}: FechaNacProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { handleSubmit, control, setValue, formState, trigger, watch, setFocus } = useForm<FormValues>();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [initialValidationDone, setInitialValidationDone] = useState(false);
  const setAnioValue = watch("setAnio");
  const setMesValue = watch("setMes");
  const setDiaValue = watch("setDia");
  const [isDerivarAportes, setIsDerivarAportes] = useState<boolean>(false);


  const mesFocus = useRef<HTMLInputElement | null>(null);
  const anioFocus = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const validateYear = async () => {
      // Validar la edad en tiempo real al ingresar el cuarto carácter del año
      if (initialValidationDone && setDiaValue !== "" && setMesValue !== "" && setAnioValue !== "") {
        const ageValidationResult = validateAge(setAnioValue, setMesValue, setDiaValue);
        if (!ageValidationResult.ok) {
          setErrorMessage(ageValidationResult.msg);
          setIsButtonEnabled(false);
        } else {
          setErrorMessage(null);
          setIsButtonEnabled(true);
        }
      }
    };

    validateYear();
  }, [initialValidationDone, setAnioValue, setMesValue, setDiaValue, setValue, trigger, watch]);

  useEffect(() => {
    // Realizar la validación inicial solo después de que se haya cargado la página
    setInitialValidationDone(true);
  }, []);

  useEffect(() => {
    if (setDiaValue && setDiaValue.length === 2 && mesFocus.current) {
      mesFocus.current.focus();
    }
  }, [setDiaValue]);

  useEffect(() => {
    if (setMesValue && setMesValue.length === 2 && anioFocus.current) {
      anioFocus.current.focus();
    }
  }, [setMesValue]);

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    if (values.setDia.length === 1) {
      values.setDia = "0" + values.setDia
    }
    if (values.setMes.length === 1) {
      values.setMes = "0" + values.setMes
    }

    const { setDia, setMes, setAnio } = values;

    setCotizacionValues({
      ...cotizacionValues,
      fechaNac: {
        dia: setDia,
        mes: setMes,
        año: setAnio,
      },
      derivaAportes: isDerivarAportes
    });
    if (isDerivarAportes) {
      if (compania) {
        nav("/prepaga/individuo/cotizacion/" + compania + "/sueldo-bruto")
      }
      else {
        nav("/prepaga/individuo/cotizacion/sueldo-bruto");
      }
    }
    else {
      if (compania) {
        nav("/prepaga/individuo/cotizacion/" + compania + "/observaciones")
      }
      else {
        nav("/prepaga/individuo/cotizacion/observaciones")
      }
    }
  };

  useEffect(() => {
    if (cotizacionValues.derivaAportes) {
      setIsDerivarAportes(cotizacionValues.derivaAportes)
    }
  }, []);

  useEffect(() => {
    setValue("setDia", fechaNacValue?.dia ? fechaNacValue.dia : ""); // Actualiza el valor en React Hook Form
    setValue("setMes", fechaNacValue?.mes ? fechaNacValue.mes : "");
    setValue("setAnio", fechaNacValue?.año ? fechaNacValue.año : "");
    fechaNacValue?.dia && trigger();
    fechaNacValue?.mes && trigger();
    fechaNacValue?.año && trigger();
  }, [fechaNacValue]);

  useEffect(() => {
    (!cotizacionValues.provincia || !cotizacionValues.localidad) && (compania ? nav("/prepaga/individuo/cotizacion/" + compania + "/localidad-provincia") : nav("/prepaga/individuo/cotizacion/localidad-provincia"))
  }, [])

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <FormWrapper className="frm01" style={{}}>
        <legend>
          {"¿Cuál es tu fecha de nacimiento? "}
          <SegTooltip text="Influye en el precio." />
        </legend>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row gx- text-start mw-sm-450 m-auto">
            <div className="col-4">
              <Controller
                name="setDia"
                control={control}
                rules={{
                  validate: {
                    customValidation: (value) => {
                      if (!value) {
                        return "Día debe incluir 2 dígitos";
                      }
                      const parsedValue = parseInt(value, 10);
                      if (isNaN(parsedValue) || parsedValue > 31) {
                        return "Día no puede ser mayor a 31";
                      }
                      if (isNaN(parsedValue) || parsedValue <= 0) {
                        return "Día no puede ser 0";
                      }
                      const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                      if (isSingleDigitWithoutZero) {
                        return "Día debe incluir 2 dígitos";
                      }
                      return true; // La validación es exitosa
                    },
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput

                    label="Día"
                    name="setDia"
                    type="number"
                    width={100}
                    placeHolder="DD"
                    value={field.value}
                    autoFocus={true}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                      field.onChange(trimmedValue);
                      setValue("setDia", trimmedValue); // Actualiza el valor en React Hook Form
                      trigger("setDia");
                    }}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${formState.errors.setDia
                      ? "var(--bs-form-invalid-border-color)"
                      : ""
                      }`}
                    focusBorderColor={`${formState.errors.setDia
                      ? "var(--bs-form-invalid-border-color)"
                      : "#2c7dfd"
                      }`}
                  />
                )}
              />
            </div>
            <div className="col-4">
              <Controller
                name="setMes"
                control={control}
                rules={{
                  validate: {
                    customValidation: (value) => {
                      if (!value) {
                        return "Mes debe incluir 2 dígitos";
                      }
                      const parsedValue = parseInt(value, 10);
                      if (isNaN(parsedValue) || parsedValue > 12) {
                        return "Mes no puede ser mayor a 12";
                      }
                      if (isNaN(parsedValue) || parsedValue <= 0) {
                        return "Día no puede ser 0";
                      }
                      const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                      if (isSingleDigitWithoutZero) {
                        return "Mes debe incluir 2 dígitos";
                      }
                      return true; // La validación es exitosa
                    },
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput
                    ref={mesFocus}
                    label="Mes"
                    name="setMes"
                    type="number"
                    width={100}
                    placeHolder="MM"
                    value={field.value}
                    autoFocus={false}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                      field.onChange(trimmedValue);
                      setValue("setMes", trimmedValue); // Actualiza el valor en React Hook Form
                      trigger("setMes");
                    }}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${formState.errors.setMes
                      ? "var(--bs-form-invalid-border-color)"
                      : ""
                      }`}
                    focusBorderColor={`${formState.errors.setMes
                      ? "var(--bs-form-invalid-border-color)"
                      : "#2c7dfd"
                      }`}
                  />
                )}
              />
            </div>
            <div className="col-4">
              <Controller
                name="setAnio"
                control={control}
                rules={{
                  validate: {
                    required: (value) =>
                      !!value || "Ingresá el año de nacimiento",
                    pattern: (value) =>
                      /^\d{4}$/.test(value) || "Año debe incluir 4 dígitos",
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput
                    ref={anioFocus}
                    label="Año"
                    name="setAnio"
                    type="number"
                    placeHolder="AAAA"
                    value={field.value}
                    autoFocus={false}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.slice(0, 4).replace(/[^0-9]/g, '');
                      field.onChange(trimmedValue);
                      setValue("setAnio", trimmedValue); // Actualiza el valor en React Hook Form
                      trigger("setAnio");
                    }}
                    width={100}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${formState.errors.setAnio
                      ? "var(--bs-form-invalid-border-color)"
                      : ""
                      }`}
                    focusBorderColor={`${formState.errors.setAnio
                      ? "var(--bs-form-invalid-border-color)"
                      : "#2c7dfd"
                      }`}
                  />
                )}
              />
            </div>
          </div>
          {errorMessage && (
            <div className="error-message" style={{ color: "red" }}>
              {errorMessage}
            </div>
          )}
          <div className="d-flex justify-content-center mt-18">
            <h5 className="fw-bold pe-20">¿Querés derivar aportes con tu recibo de sueldo?</h5>
            <SegToggle idLabel="reciboSueldo" label="No" isChecked={isDerivarAportes} onChange={setIsDerivarAportes} />
            <div style={{ marginLeft: "-8px" }}>Si</div>
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary mb-4"
              disabled={
                !formState.isValid || !isButtonEnabled // Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </FormWrapper>
    </div>
  );
};
