import { useState } from 'react';
import styled from '@emotion/styled';
import { SegTextarea } from '../../../../../Components/SegUI/SegTextArea/SegTextArea';
import { SegButton } from '../../../../../Components/SegUI/SegButton/SegButton';
import { StepComponentProps } from '../../Siniestros';
import { SiniestroData, useSiniestroStore } from '../../../../../Services/Providers/SiniestroStore';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GuardarSiniestro } from '../../../../../Services/API/GuardarSiniestro';

interface DatosCristales {
  parabrisas: boolean;
  luneta: boolean;
  delanteroConductor: boolean;
  delanteroAcompañante: boolean;
  traseroConductor: boolean;
  traseroAcompañante: boolean;
}

interface DatosCerraduras {
  delanteraConductor: boolean;
  delanteraAcompañante: boolean;
  baul: boolean;
}

const CheckboxContainer = styled.div`
  margin-bottom: 1rem;
`;

const CustomCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 4px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ span {
    background-color: #007bff;
  }

  input:checked ~ span:after {
    display: block;
  }
`;

export const RoturaCristalesCerraduras = ({ setSteps, config }: StepComponentProps) => {
  const { siniestroValues, setSiniestroValues } = useSiniestroStore();
  const { formState, handleSubmit, control, setValue, trigger, watch } = useForm({
    defaultValues: {
      relatoHechos: '',
      cristales: {
        parabrisas: false,
        luneta: false,
        delanteroConductor: false,
        delanteroAcompañante: false,
        traseroConductor: false,
        traseroAcompañante: false
      } as DatosCristales,
      cerraduras: {
        delanteraConductor: false,
        delanteraAcompañante: false,
        baul: false
      } as DatosCerraduras
    }
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enviado, setEnviado] = useState<boolean>(false);

  /*const validateForm = (): boolean => {
    const hayCristales = Object.values(formData.cristales).some(v => v === true);
    const hayCerraduras = Object.values(formData.cerraduras).some(v => v === true);
    const isValid = formData.relatoHechos.trim() !== '' && (hayCristales || hayCerraduras);

    if (!isValid) {
      setErrorMessage('Por favor complete el relato y seleccione al menos un daño');
    }

    return isValid;
  };*/

  const renderTitle = (): string => {
    switch (siniestroValues.tipoSiniestro) {
      case "AccidenteTransito": return "Accidente de tránsito"
      case "RoturaCristalesCerraduras": return "Rotura de cristales/cerraduras"
      case "RoboTotal": return "Robo total"
      case "RoboParcial": return "Robo parcial"
      case "DanioParcial": return "Daño parcial"
    }
    return ""
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsLoading(true);
    setSiniestroValues({
      ...siniestroValues,
      tipoSiniestro: "RoturaCristalesCerraduras",
      relatoHechos: data.relatoHechos,
      cristales: data.cristales,
      cerraduras: data.cerraduras
    });

    const resp = await GuardarSiniestro({
      ...siniestroValues,
      tipoSiniestro: "RoturaCristalesCerraduras",
      relatoHechos: data.relatoHechos,
      cristales: data.cristales,
      cerraduras: data.cerraduras
    } as SiniestroData);

    setIsLoading(false);
    if (!resp.caseId) {
      setErrorMessage("Ocurrio un error, intentalo otra vez.");
    } else {
      setErrorMessage("");
      setEnviado(true);
    }

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row justify-content-center pt-4 pb-4">
      <div className="col-md-8">
        <legend className='mb-4 fw-bold'>{renderTitle()}</legend>
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}

        <Controller
          name="relatoHechos"
          control={control}
          rules={{ required: "El relato de los hechos es obligatorio" }}
          render={({ field, fieldState }) => (
            <SegTextarea
              label="Relato de los hechos"
              placeHolder="Describe lo más detallado posible cómo sucedió el daño"
              width={100}
              name=""
              className='mb-3'
              onChange={(e) => {
                field.onChange(e);
                setValue("relatoHechos", e.target.value);
                trigger("relatoHechos");
              }}
              value={field.value}
              leyendaError={fieldState?.error?.message}
              borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
              focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
            />

          )}
        />

        <div className="card p-3 mb-3">
          <h5>Cristales</h5>
          <Controller
            name="cristales"
            control={control}
            rules={{
              validate: (value) => 
                Object.values(value || {}).some((checked) => checked)
                  ? true
                  : Object.values(watch("cerraduras")).some((checked) => checked) ? true 
                  :
                  "Debes seleccionar al menos un cristal.",
                
            }}
            render={({ field: { value = {}, onChange } }) => (
              <>
                <CustomCheckbox>
                  Parabrisas
                  <input
                    type="checkbox"
                    checked={value.parabrisas || false}
                    onChange={(e) =>
                      onChange({ ...value, parabrisas: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Luneta
                  <input
                    type="checkbox"
                    checked={value.luneta || false}
                    onChange={(e) =>
                      onChange({ ...value, luneta: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Delantero Conductor
                  <input
                    type="checkbox"
                    checked={value.delanteroConductor || false}
                    onChange={(e) =>
                      onChange({ ...value, delanteroConductor: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Delantero Acompañante
                  <input
                    type="checkbox"
                    checked={value.delanteroAcompañante || false}
                    onChange={(e) =>
                      onChange({ ...value, delanteroAcompañante: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Trasero Conductor
                  <input
                    type="checkbox"
                    checked={value.traseroConductor || false}
                    onChange={(e) =>
                      onChange({ ...value, traseroConductor: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Trasero Acompañante
                  <input
                    type="checkbox"
                    checked={value.traseroAcompañante || false}
                    onChange={(e) =>
                      onChange({ ...value, traseroAcompañante: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>
              </>
            )}
          />

          {/* Mensaje de error */}
          {formState.errors.cristales && <p style={{ color: "red" }}>{formState.errors.cristales.message}</p>}
        </div>

        <div className="card p-3 mb-3">
          <h5>Cerraduras</h5>
          <Controller
            name="cerraduras"
            control={control}
            rules={{
              validate: (value) =>
                Object.values(value || {}).some((checked) => checked)
                  ? true
                  : Object.values(watch("cristales")).some((checked) => checked) ? true 
                  : "Debes seleccionar al menos una cerradura.",
            }}
            render={({ field: { value = {}, onChange } }) => (
              <>
                <CustomCheckbox>
                  Cerradura delantera conductor
                  <input
                    type="checkbox"
                    checked={value.delanteraConductor || false}
                    onChange={(e) =>
                      onChange({ ...value, delanteraConductor: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Cerradura delantera acompañante
                  <input
                    type="checkbox"
                    checked={value.delanteraAcompañante || false}
                    onChange={(e) =>
                      onChange({ ...value, delanteraAcompañante: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>

                <CustomCheckbox>
                  Cerradura baúl
                  <input
                    type="checkbox"
                    checked={value.baul || false}
                    onChange={(e) =>
                      onChange({ ...value, baul: e.target.checked })
                    }
                  />
                  <span className="checkmark"></span>
                </CustomCheckbox>
              </>
            )}
          />
          {/* Mensaje de error */}
          {formState.errors.cerraduras && <p style={{ color: "red" }}>{formState.errors.cerraduras.message}</p>}

        </div>
        {enviado &&
          <div className="alert alert-success" role="alert">
            Denuncia enviada correctamente
          </div>
        }
        <div className="text-center mt-4">
          <SegButton
            label={isLoading ? "Enviando denuncia" : "Enviar denuncia"}
            type="submit"
            disabled={!formState.isValid || enviado}
            loading={isLoading}
            bgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
            hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
            hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
            hoverOpacity={config.origen !== "SEGURARSE"}
          />
        </div>
      </div>
    </form>
  );
};
