import { useState, useEffect } from 'react';
import { useForm, FormProvider, Controller, SubmitHandler, FieldValues } from 'react-hook-form';
import { SegInput } from '../../../../Components/SegUI/SegInput/SegInput';
import { SegButton } from '../../../../Components/SegUI/SegButton/SegButton';
import { StepComponentProps } from '../Siniestros';
import { useSiniestroStore } from '../../../../Services/Providers/SiniestroStore';
import obtenerProvincias from '../../../../Services/API/Provincias';
import { SegSelect, SegSelectOption } from '../../../../Components/SegUI/SegSelect/SegSelect';
import obtenerLocalidaXProvincia from '../../../../Services/API/Localidades';
import obtenerCodigoPostalPorLocalidad from '../../../../Services/API/CodigoPostal';
import { useNavigate } from 'react-router-dom';


const PAISES = [
  { value: "Argentina", label: "Argentina" },
  { value: "Brasil", label: "Brasil" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Uruguay", label: "Uruguay" }
]

interface IUbicacionSiniestro {
  fecha: string;
  hora: string;
  calle: string;
  numero: string;
  pais: string;
  provincia: string;
  localidad: string;
  codigoPostal: string;
}

export const UbicacionSiniestro = ({ setSteps, config, isDisabled = false}: StepComponentProps) => {

  const { siniestroValues, setSiniestroValues } = useSiniestroStore();
  const [isArgentina, setIsArgentina] = useState<boolean>(false);
  const [provincias, setProvincias] = useState<SegSelectOption[]>([]);
  const [localidades, setLocalidades] = useState<SegSelectOption[]>([]);


  const [paisSelected, setPaisSelected] = useState<SegSelectOption>();
  const [provinciaSelected, setProvinciaSelected] = useState<SegSelectOption>();
  const [localidadSelected, setLocalidadSelected] = useState<SegSelectOption>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const nav = useNavigate();


  const { getValues, formState, setValue, trigger, control, handleSubmit } = useForm({
    defaultValues: {
      fecha: siniestroValues.fecha || '',
      hora: siniestroValues.hora || '',
      calle: siniestroValues.calle || '',
      numero: siniestroValues.numero || '',
      pais: siniestroValues.pais || '',
      provincia: siniestroValues.provincia || '',
      localidad: siniestroValues.localidad || '',
      codigoPostal: siniestroValues.codigoPostal || ''
    },

  });
  const methods = useForm<IUbicacionSiniestro>();

  useEffect(() => {
    if (siniestroValues.pais) {
      setPaisSelected(PAISES.find(pais => pais.value === siniestroValues.pais))
    }
  }, [])

  const [errorMessage, setErrorMessage] = useState('');

  const validateForm = (): boolean => {
    const values = getValues();
    const isValid = Object.values(values).every(value => value !== "");

    if (!isValid) {
      setErrorMessage('Por favor complete todos los campos requeridos');
    } else {
      setErrorMessage('');
    }

    return isValid;
  };

  const handleBack = (): string => {
    // Si es necesario manejar alguna lógica específica al volver atrás desde este paso
    // Por ahora solo aseguramos que el componente padre maneje el retroceso
    return "";
  };

  const getProvincias = async (): Promise<SegSelectOption[]> => {
    setIsLoading(true)
    const response = await obtenerProvincias();
    const formattedData = response.map((item: any) => ({
      value: item,
      label: item,
    }));
    setIsLoading(false);
    return formattedData;
  }

  const getLocalidades = async (provincia: string): Promise<SegSelectOption[]> => {
    setIsLoading(true)
    const response = await obtenerLocalidaXProvincia(provincia);
    const formattedData = response.map((item: any) => ({
      value: item,
      label: item,
    }));
    setIsLoading(false);
    return formattedData;
  }

  const handlePaisChange = async (e: SegSelectOption) => {
    setPaisSelected(e);
    setProvinciaSelected(undefined);
    setLocalidadSelected(undefined);
    setValue("provincia", "");
    setValue("localidad", "");
    setValue("codigoPostal", "");
    setValue("calle", "");
    setValue("numero", "");
    if (e.value === "Argentina") {
      setIsArgentina(true);
      setProvincias(await getProvincias());
    } else {
      setIsArgentina(false)
    }
  }

  const handleProviciaChange = async (e: SegSelectOption) => {
    setProvinciaSelected(e);
    setLocalidadSelected(undefined);
    setValue("localidad", "");
    setValue("codigoPostal", "");
    setValue("calle", "");
    setValue("numero", "");
    setLocalidades(await getLocalidades(e.value));
  }

  const handleLocalidadChange = async (e: SegSelectOption) => {
    setLocalidadSelected(e);
    const { ok, cod } = await obtenerCodigoPostalPorLocalidad(`${provinciaSelected?.value};${e.value}`);
    if (ok) {
      setValue("codigoPostal", cod);
    }
  }


  const onSubmit: SubmitHandler<IUbicacionSiniestro> = (values: IUbicacionSiniestro) => {
    setValue("pais", paisSelected!.value);
    if (isArgentina) {
      setValue("provincia", provinciaSelected!.value);
      setValue("localidad", localidadSelected!.value);
    }
    if (validateForm()) {
      setSiniestroValues({
        ...siniestroValues,
        ...values,
        isArgentina,
        //pais: isArgentina ? paisSelected?.value : values.pais,
        pais: paisSelected?.value,
        provincia: isArgentina ? provinciaSelected?.value : values.provincia,
        localidad: isArgentina ? localidadSelected?.value : values.localidad,
      });

      setSteps({ step: 1, subStep: 1 });
      return nav("/siniestros/vehiculos/tipo-siniestro")
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <FormProvider {...methods}>
        <div className="row justify-content-center pt-4 pb-4">
          <div className="col-md-8 mb-10">
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}

            <div>
              <fieldset className="row col-md-12">
                <legend className='mt-2 mb-2 fw-bold'>Datos del siniestro</legend>
                <div className='col-md-6'>
                  {/* Fecha */}
                  <Controller
                    name="fecha"
                    control={control}
                    rules={{
                      validate: {
                        required: (value) => !!value || "Ingresá la fecha del siniestro",
                        pastDate: (value) => {
                          const today = new Date();
                          today.setHours(0, 0, 0, 0);
                          const selectedDate = new Date(value);
                          selectedDate.setDate(selectedDate.getDate())
                          return selectedDate <= today || "La fecha no puede ser posterior al día actual";
                        },
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <SegInput
                        className="w-full mt-3"
                        label="Fecha"
                        name="fecha"
                        type="date"
                        width={100}
                        placeHolder="dd/mm/aaaa"
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("fecha", e.target.value);
                          trigger("fecha");
                          trigger("hora")
                        }}
                        value={field.value}
                        autoFocus={true}
                        leyendaError={fieldState?.error?.message}
                        borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                        focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
                        disabled={isDisabled}
                      />
                    )}
                  />
                </div>
                {/* Hora */}
                <div className='col-md-6'>
                  <Controller
                    name="hora"
                    control={control}
                    rules={{
                      required: "Ingresá la hora del siniestro",
                      validate: (value) => {
                        const today = new Date().toISOString().split("T")[0]; // Fecha actual en formato YYYY-MM-DD
                        const currentTime = new Date().toTimeString().slice(0, 5);
                        if (getValues("fecha") === today && value > currentTime){
                          return `La hora no puede ser mayor a la actual (${currentTime})`;
                        }
                        return true
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <SegInput
                        className="w-full mt-3"
                        label="Hora"
                        name="hora"
                        type="time"
                        width={100}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("hora", e.target.value);
                          trigger("hora");
                        }}
                        value={field.value}
                        leyendaError={fieldState?.error?.message}
                        borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                        focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
                        disabled={isDisabled}
                      />
                    )}
                  />
                </div>


                {/*Pais*/}
                <div className='col-md-12 mt-3'>
                  <label className="form-label">Pais</label>
                  <SegSelect
                    selected={paisSelected}
                    setSelected={handlePaisChange}
                    name="pais"
                    options={PAISES}
                    width={"100%"}
                    //disabled={localidadDis}
                    //loading={localidadLoad}
                    //borderColor={errors.localidad ? "red" : ""}
                    openDefault={false}
                    disabled={isDisabled}
                  />
                  {/*<p>{errors.localidad?.message}</p>*/}
                </div>

                {/*Provincia*/}
                {isArgentina ?
                  <div className='col-md-12 mt-3'>
                    <label className="form-label">Provincia</label>
                    <SegSelect
                      selected={provinciaSelected}
                      setSelected={handleProviciaChange}
                      name="localidad"
                      placeholder={isLoading ? "Cargando.." : "Escribí aquí"}
                      options={provincias}
                      width={"100%"}
                      //disabled={localidadDis}
                      loading={isLoading}
                      //borderColor={errors.localidad ? "red" : ""}
                      openDefault={false}
                    />
                    {/*<p>{errors.localidad?.message}</p>*/}
                  </div>
                  :
                  <Controller
                    name="provincia"
                    control={control}
                    rules={{
                      required: "Ingresá la provincia"
                    }}
                    render={({ field, fieldState }) => (
                      <SegInput
                        className="w-full mt-3"
                        label="Provincia"
                        name="provincia"
                        type="text"
                        width={100}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("provincia", e.target.value);
                          trigger("provincia");
                        }}
                        value={field.value}
                        leyendaError={fieldState?.error?.message}
                        borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                        focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
                        disabled={isDisabled}
                      />
                    )}
                  />
                }

                {/*Localidad*/}
                {isArgentina ?
                  <div className='col-md-6 mt-3'>
                    <label className="form-label">Localidad</label>
                    <SegSelect
                      selected={localidadSelected}
                      setSelected={handleLocalidadChange}
                      name="localidad"
                      options={localidades}
                      placeholder={isLoading ? "Cargando.." : "Escribí aquí"}
                      width={"100%"}
                      //disabled={localidadDis}
                      loading={isLoading}
                      //borderColor={errors.localidad ? "red" : ""}
                      openDefault={false}
                      disabled={isDisabled}
                    />
                    {/*<p>{errors.localidad?.message}</p>*/}
                  </div>
                  :
                  <div className='col-md-6 mt-3'>
                    <Controller
                      name="localidad"
                      control={control}
                      rules={{
                        required: "Ingresá la localidad"
                      }}
                      render={({ field, fieldState }) => (
                        <SegInput
                          className="w-full"
                          label="Localidad"
                          name="localidad"
                          type="text"
                          width={100}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue("localidad", e.target.value);
                            trigger("localidad");
                          }}
                          value={field.value}
                          leyendaError={fieldState?.error?.message}
                          borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                          focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
                          disabled={isDisabled}
                        />
                      )}
                    />
                  </div>
                }

                {/* Código Postal */}
                <div className='col-md-6'>
                  <Controller
                    name="codigoPostal"
                    control={control}
                    rules={{
                      required: "Ingresá el código postal",
                      pattern: { value: /^\d{4}$/, message: "El código postal debe tener 4 dígitos" }
                    }}
                    render={({ field, fieldState }) => (
                      <SegInput
                        className="w-full mt-3"
                        label="Código Postal"
                        name="codigoPostal"
                        type="number"
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                            e.preventDefault()
                          }
                        }}
                        width={100}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("codigoPostal", e.target.value);
                          trigger("codigoPostal");
                        }}
                        value={field.value}
                        leyendaError={fieldState?.error?.message}
                        borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                        focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
                        disabled={isDisabled}
                      />
                    )}
                  />
                </div>


                {/* Calle */}
                <div className='col-md-6'>
                  <Controller
                    name="calle"
                    control={control}
                    rules={{
                      required: "Ingresá la calle",
                      minLength: { value: 3, message: "La calle debe tener al menos 3 caracteres" }
                    }}
                    render={({ field, fieldState }) => (
                      <SegInput
                        className="w-full mt-3"
                        label="Calle"
                        name="calle"
                        type="text"
                        width={100}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("calle", e.target.value);
                          trigger("calle");
                        }}
                        value={field.value}
                        leyendaError={fieldState?.error?.message}
                        borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                        focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
                        disabled={isDisabled}
                      />
                    )}
                  />
                </div>

                {/* Número */}
                <div className='col-md-6'>
                  <Controller
                    name="numero"
                    control={control}
                    rules={{
                      required: "Ingresá el número",
                      pattern: { value: /^\d+$/, message: "Ingresá solo números" }
                    }}
                    render={({ field, fieldState }) => (
                      <SegInput
                        className="w-full mt-3"
                        label="Número"
                        name="numero"
                        type="number"
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                            e.preventDefault()
                          }
                        }}
                        width={100}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("numero", e.target.value);
                          trigger("numero");
                        }}
                        value={field.value}
                        leyendaError={fieldState?.error?.message}
                        borderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : ""}
                        focusBorderColor={fieldState?.error ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}
                        disabled={isDisabled}
                      />
                    )}
                  />
                </div>
              </fieldset>
            </div>

            <div className="text-center mt-4">
              <SegButton
                label="Continuar"
                type="submit"
                disabled={!formState.isValid}
                bgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
                hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
                hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                hoverOpacity={config.origen !== "SEGURARSE"}
              />
            </div>
          </div>
        </div>
      </FormProvider>
    </form>
  );
};

