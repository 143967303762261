import {create} from 'zustand';
import {persist} from "zustand/middleware";

interface DataPoliza {
  AccountId: string;
  PolizaId: string;
  NumeroPoliza: string;
  NombreApellido: string;
  Compania: string;
  RiesgoId?: string;
}
export interface Tercero {
  nombreApellido: string;
  patente: string;
  compania: string;
  danios: string;
}
export interface PersonaLesionada {
  nombreApellido: string;
  detalleLesiones: string;
  dni: string;
  telefono: string;
  vehiculo: string;
}

export type TipoSiniestro = "AccidenteTransito" | "RoturaCristalesCerraduras" | "RoboTotal" | "RoboParcial" | "DanioParcial"|"";
export interface SiniestroData {
  poliza: DataPoliza | null;
  fecha: string;
  hora: string;
  calle: string;
  numero: string;
  pais: string;
  provincia: string;
  localidad: string;
  codigoPostal: string;
  isArgentina: boolean;
  terceros: Tercero[];
  personasLesionadas: PersonaLesionada[];
  tipoSiniestro: TipoSiniestro;
  relatoHechos?:string;
  daniosVehiculo?: string;
  registroFrente?: string | null;
  registroDorso?: string | null;
  huboTerceros?: boolean;
  huboLesionados?: boolean;
  // Propiedades para daño parcial
  tiposDanio?: { granizo?: boolean; inundacion?: boolean; incendio?: boolean };
  denunciaBomberos?: File | null;
  // NUEVO: Propiedades para robo parcial
  ruedasRobo?: {
    delanteraDerecha?: boolean;
    delanteraIzquierda?: boolean;
    traseraDerecha?: boolean;
    traseraIzquierda?: boolean;
    auxilio?: boolean;
  };
  bateriaRobo?: boolean;
  denunciaPolicial?: string | null;
  // NUEVO: Propiedades para "RoturaCristalesCerraduras"
  cristales?: {
    parabrisas: boolean;
    luneta: boolean;
    delanteroConductor: boolean;
    delanteroAcompañante: boolean;
    traseroConductor: boolean;
    traseroAcompañante: boolean;
  };

  cerraduras?: {
    delanteraConductor: boolean;
    delanteraAcompañante: boolean;
    baul: boolean;
  };
  // Agregar aquí más campos según se necesiten para los siguientes pasos
}

interface SiniestroStore {
  siniestroValues: Partial<SiniestroData>;
  setSiniestroValues: (values: Partial<SiniestroData>) => void;
  addTercero: (tercero: Tercero) => void;
  updateTercero: (index: number, tercero: Tercero) => void;
  deleteTercero: (index: number) => void;
  addPersonaLesionada: (persona: PersonaLesionada) => void;
  updatePersonaLesionada: (index: number, persona: PersonaLesionada) => void;
  deletePersonaLesionada: (index: number) => void;
}

export const useSiniestroStore = create<SiniestroStore>()(
  persist(
    (set) => ({
      siniestroValues: {
        fecha: undefined,
        hora: undefined,
        calle: undefined,
        numero: undefined,
        pais: undefined,
        provincia: undefined,
        localidad: undefined,
        codigoPostal: undefined,
        isArgentina: false,
        terceros: [],
        personasLesionadas: [],
        tipoSiniestro: undefined,
      },
      setSiniestroValues: (values) =>
        set((state) => ({
          siniestroValues: {
            ...state.siniestroValues,
            ...values,
          },
        })),
      addTercero: (tercero) =>
        set((state) => ({
          siniestroValues: {
            ...state.siniestroValues,
            terceros: [...(state.siniestroValues.terceros || []), tercero],
          },
        })),
      updateTercero: (index, tercero) =>
        set((state) => ({
          siniestroValues: {
            ...state.siniestroValues,
            terceros:
              state.siniestroValues.terceros?.map((t, i) =>
                i === index ? tercero : t
              ) || [],
          },
        })),
      deleteTercero: (index) =>
        set((state) => ({
          siniestroValues: {
            ...state.siniestroValues,
            terceros:
              state.siniestroValues.terceros?.filter((_, i) => i !== index) ||
              [],
          },
        })),
      addPersonaLesionada: (persona) =>
        set((state) => ({
          siniestroValues: {
            ...state.siniestroValues,
            personasLesionadas: [
              ...(state.siniestroValues.personasLesionadas || []),
              persona,
            ],
          },
        })),
      updatePersonaLesionada: (index, persona) =>
        set((state) => ({
          siniestroValues: {
            ...state.siniestroValues,
            personasLesionadas:
              state.siniestroValues.personasLesionadas?.map((p, i) =>
                i === index ? persona : p
              ) || [],
          },
        })),
      deletePersonaLesionada: (index) =>
        set((state) => ({
          siniestroValues: {
            ...state.siniestroValues,
            personasLesionadas:
              state.siniestroValues.personasLesionadas?.filter(
                (_, i) => i !== index
              ) || [],
          },
        })),
    }),
    {
      name: 'siniestros', // Nombre de la clave en sessionStorage
      getStorage: () => sessionStorage, // Utiliza sessionStorage en lugar de localStorage
    }
  )
);
