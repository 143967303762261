import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import { PorQueElegirnos } from '../Components/PorQueElegirnos'
import PropuestaValor from '../Components/PropuestaValor'
import Asesoramiento from '../Components/Asesoramiento'
import Formulario from '../Components/Formulario';
import useSEOConfig from "../../../hooks/seo/useSEOConfig"

export const ResponsabilidadCivil = () => {

    useSEOConfig(
        {
            title: "Seguros Personales y Profesionales - Asegurá tu tranquilidad",
            description: "Conocé la amplia variedad de seguros que tenemos para vos · Auto · Accidentes Personales · Moto · Hogar · Asistencia al viajero · Mala Praxis · Vida · Celular",
            keywords: "Seguros de Auto, Seguro para moto, Seguros económicos, Cotizar seguro de auto, Seguro Mala praxis, Seguro online, Asistencia al viajero, Aseguradoras, Seguros rivadavia, Seguro flota, Cotizar seguro",
            ogTitle: "Segurarse - Bróker Integral de Seguros digitales",
            ogDescription: "Contratá el seguro que necesitás en pocos pasos. Seguros personales a tu medida al mejor precio. Te asesoramos en todo momento ¡Invertí en tu tranquilidad!",
            ogImage: "https://segurarse.com.ar/ContentS/img/metadatos_banner.png",
            twitterCard: "summary_large_image",
        }
    )

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Responsabilidad Civil'
                    url='#formulario'
                    icon='#icon-seg-rc'
                    subTitulo={{ __html: "Protegé el patrimonio de tu empresa ante un reclamo de terceros a causa de daños físicos o materiales.<br/><br/>¿Empezamos?" }}
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué cubre?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Este seguro está diseñado para proteger tu patrimonio ante reclamos de terceros, a causa de hechos accidentales a los que está expuesta tu empresa por su propia actividad. Contratado este seguro, el patrimonio de tu empresa queda resguardado frente al pago de indemnizaciones a los terceros que resulten afectados por tus acciones.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Dentro de las principales coberturas, se incluyen:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños a terceros:</b> daños o lesiones causados a terceros debido a la negligencia o acciones de la entidad aseguradora. Incluye lesiones personales, daños a la propiedad y perjuicios económicos.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Gastos médicos:</b> el seguro puede cubrir los gastos médicos necesarios para tratar lesiones sufridas por terceros debido a la acción de la persona asegurada. Puede incluir costos de atención médica, hospitalización, medicamentos y terapia de rehabilitación.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Defensa legal:</b> ante una demanda legal, la cobertura puede cubrir honorarios legales, costos judiciales, y gastos relacionados a la defensa del caso.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Daños a la propiedad:</b> Si la persona asegurada causa daños a la propiedad de un tercero, ya sea de manera accidental o negligente, el seguro de responsabilidad civil puede cubrir los costos de reparación o reemplazo de la propiedad dañada.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Formulario
                    rubro='RC'
                    titulo='Cotizá seguro de Responsabilidad Civil'
                />
                <PorQueElegirnos />
                <PropuestaValor />
                <Asesoramiento />
            </div>
        </>
    )
}
