import React, { useRef, useState } from 'react'
import { useSiniestroStore } from '../../../../../Services/Providers/SiniestroStore';
import { SegButton } from '../../../../../Components/SegUI/SegButton/SegButton';
import { SegInput } from '../../../../../Components/SegUI/SegInput/SegInput';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export interface IPersonaLesionada {
    nombreApellido: string;
    detalleLesiones: string;
    dni: string;
    telefono: string;
    vehiculo: string;
}

export const ModalPersonasLesionadas = () => {

    const [showForm, setShowForm] = useState(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [formData, setFormData] = useState({
        nombreApellido: '',
        detalleLesiones: '',
        dni: '',
        telefono: '',
        vehiculo: ''
    });
    const [errors, setErrors] = useState<Record<string, string>>({});

    const { siniestroValues, setSiniestroValues } = useSiniestroStore();
    const personasLesionadas = siniestroValues.personasLesionadas || [];
    const btnCloseRef: any = useRef();

    const validateForm = () => {
        const newErrors: Record<string, string> = {};

        if (!formData.nombreApellido) newErrors.nombreApellido = "Este campo es requerido";
        if (!formData.detalleLesiones) newErrors.detalleLesiones = "Este campo es requerido";
        if (!formData.dni) newErrors.dni = "Este campo es requerido";
        if (!formData.telefono) newErrors.telefono = "Este campo es requerido";
        if (!formData.vehiculo) newErrors.vehiculo = "Este campo es requerido";

        // Validaciones adicionales
        if (formData.dni && !/^\d{7,8}$/.test(formData.dni)) {
            newErrors.dni = "El DNI debe tener 7 u 8 dígitos";
        }
        if (formData.telefono && !/^\d{10}$/.test(formData.telefono)) {
            newErrors.telefono = "El teléfono debe tener 10 dígitos";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (validateForm()) {
            const updatedPersonas = editIndex !== null
                ? personasLesionadas.map((p, i) => i === editIndex ? formData : p)
                : [...personasLesionadas, formData];

            setSiniestroValues({
                ...siniestroValues,
                personasLesionadas: updatedPersonas
            });

            // Reset form
            setFormData({
                nombreApellido: '',
                detalleLesiones: '',
                dni: '',
                telefono: '',
                vehiculo: ''
            });
            setShowForm(false);
            setEditIndex(null);
            btnCloseRef.current.click();
        }
    };

    const handleEdit = (index: number) => {
        setEditIndex(index);
        setFormData(personasLesionadas[index]);
        setShowForm(true);
    };

    const handleDelete = (index: number) => {
        const newSiniestroValues = {
            ...siniestroValues,
            personasLesionadas: personasLesionadas.filter((_, i) => i !== index)
        };
        setSiniestroValues(newSiniestroValues);
    };

    return (
        <div className="personas-lesionadas-container">
            <h5 className="personas-lesionadas-title">Personas lesionadas</h5>
            {personasLesionadas.length > 0 && (
                <div className="personas-lesionadas-list">
                    {personasLesionadas.map((persona: IPersonaLesionada, index: number) => (
                        <div key={index} className="persona-card">
                            <div className="persona-info">
                                <p><strong>Nombre:</strong> {persona.nombreApellido}</p>
                                <p><strong>DNI:</strong> {persona.dni}</p>
                                <p><strong>Teléfono:</strong> {persona.telefono}</p>
                                <p><strong>Lesiones:</strong> {persona.detalleLesiones}</p>
                                <p><strong>Vehículo:</strong> {persona.vehiculo}</p>
                            </div>
                            <div className="persona-actions">
                                <button type='button'
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasTerceros"
                                    onClick={() => handleEdit(index)}
                                    className="action-button edit">
                                    <EditIcon />
                                </button>
                                <button type='button' onClick={() => handleDelete(index)} className="action-button delete">
                                    <DeleteIcon />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <SegButton
                label="Agregar persona lesionada"
                //onClick={() => setShowForm(true)}
                type="button"
                className="add-tercero-button"
                disabled={personasLesionadas.length >= 3}
                data_bs_toggle="offcanvas"
                data_bs_target="#offcanvasLesionados"
            />

            <div className="offcanvas offcanvas-bottom"
                tabIndex={-1} id="offcanvasLesionados" aria-labelledby="offcanvasBottomLabel"
                style={{
                    minHeight: (window.innerWidth > 778) ? (window.innerHeight > 778) ? "90vh" : "100vh" : "90vh",
                    backgroundColor: window.innerWidth > 778 ? "transparent" : "",
                    borderTop: window.innerWidth > 778 ? "0 solid white" : ""
                }} >
                <div className="offcanvas-body ">
                    {
                        window.innerWidth < 778 &&
                        <div style={{ position: "relative", display: "flex", justifyContent: "end" }}>
                            <button type="button"
                                className="btn-close text-reset p-2 bg-white"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            //style={{ position: "absolute",}}
                            ></button>
                        </div>
                    }
                    <form onSubmit={handleSubmit}>
                        <div className={`px-20 pb-40 pb-lg-60 mx-auto ${window.innerWidth > 778 && " w-50"}`} style={{ position: "relative", marginTop: window.innerWidth < 778 ? "-16px" : "" }}>
                            <div className={window.innerWidth > 768 ? "p-4" : "mt-4"} style={{ background: "#fff", borderRadius: "8px" }}>
                                <SegInput
                                    label="Nombre y apellido"
                                    value={formData.nombreApellido}
                                    onChange={(e) => setFormData({ ...formData, nombreApellido: e.target.value })}
                                    width={100}
                                    type="text"
                                    name="nombreApellido"
                                    leyendaError={errors.nombreApellido}
                                    className="mt-3"
                                />

                                <SegInput
                                    label="DNI"
                                    value={formData.dni}
                                    onChange={(e) => setFormData({ ...formData, dni: e.target.value })}
                                    width={100}
                                    type="text"
                                    name="dni"
                                    leyendaError={errors.dni}
                                    className="mt-3"
                                />

                                <SegInput
                                    label="Teléfono"
                                    value={formData.telefono}
                                    onChange={(e) => setFormData({ ...formData, telefono: e.target.value })}
                                    width={100}
                                    type="text"
                                    name="telefono"
                                    leyendaError={errors.telefono}
                                    className="mt-3"
                                />

                                <SegInput
                                    label="Detallar lesiones y nivel de gravedad"
                                    value={formData.detalleLesiones}
                                    onChange={(e) => setFormData({ ...formData, detalleLesiones: e.target.value })}
                                    width={100}
                                    type="text"
                                    name="detalleLesiones"
                                    leyendaError={errors.detalleLesiones}
                                    className="mt-3"
                                />

                                <SegInput
                                    label="¿Qué vehículo ocupaba?"
                                    value={formData.vehiculo}
                                    onChange={(e) => setFormData({ ...formData, vehiculo: e.target.value })}
                                    width={100}
                                    type="text"
                                    name="vehiculo"
                                    leyendaError={errors.vehiculo}
                                    className="mt-3"
                                />
                                <div className={`mt-4 ${window.innerWidth > 778 && "d-flex gap-2"}`}>
                                    {/*<SegButton
                                    label="Guardar"
                                    onClick={handleSubmit}
                                    type="button"
                                    className={`save-button ${window.innerWidth < 778 && "w-100 mb-2"}`}
                                    disabled={false}
                                />*/}
                                    <button
                                        onClick={(e: any) => handleSubmit(e)}
                                        type="submit"
                                        className={`btn save-button ${window.innerWidth < 778 && "w-100 mb-2"}`}
                                        style={{ minWidth: "172px" }}
                                    >
                                        Guardar
                                    </button>
                                    <SegButton
                                        label="Cancelar"
                                        ref={btnCloseRef}
                                        data_bs_dismiss="offcanvas"
                                        onClick={() => {
                                            setShowForm(false);
                                            setEditIndex(null);
                                            setFormData({
                                                nombreApellido: '',
                                                detalleLesiones: '',
                                                dni: '',
                                                telefono: '',
                                                vehiculo: ''
                                            });
                                            setErrors({});
                                        }}
                                        type="button"
                                        className={`cancel-button ${window.innerWidth < 778 && "w-100"}`}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
