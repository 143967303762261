import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";
import { IPasos } from "../../../../Pages/Moto/Cotizador";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import obtenerVersiones from "../../../../Services/API/Versiones";
import { SegTooltip } from "../../../SegUI/SegTooltip/SegTooltip";

interface VersionProps {
    config: EmpresaProps;
    setSteps: Dispatch<SetStateAction<IPasos>>
}

export const Version = ({ config, setSteps, ...rest }: VersionProps) => {

    const [versionSelected, setVersionSelected] = useState<SegSelectOption>()
    const [versiones, setVersiones] = useState<SegSelectOption[]>()
    const nav = useNavigate();
    const methods = useForm();
    const { compania } = useParams();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

    useEffect(() => {
        //setLoading(true)
        const fetchData = async () => {

            const response: any[] = await obtenerVersiones(cotizacionValues?.marca, cotizacionValues?.modelo, cotizacionValues?.año, "moto");

            const formattedData: SegSelectOption[] = Array.isArray(response) ? response?.map((item: any) => ({
                value: item,
                label: item,
            })) : []
            setVersiones(formattedData);
        };
        fetchData().then(() => {
            //setLoading(false)
            if (cotizacionValues?.version) {
                setVersionSelected(cotizacionValues.version)
            }
        })
    }, [])

    useEffect(() => {
        !cotizacionValues.año && (compania ? nav("/moto/cotizacion/" + compania + "/anio") : nav("/moto/cotizacion/anio"))
    }, [])

    const handleSubmit = (versionSelecte: any) => {
        setCotizacionValues({
            ...cotizacionValues,
            version: versionSelecte
        })
        compania ? nav("/moto/cotizacion/" + compania + "/nombre") : nav("/moto/cotizacion/nombre");
    }


    return (
        <div {...rest}>
            <div className="mt-4 bg-gray-light">
                <div className="frm01">
                    <legend className='text-center'>
                        {"¿Cuál es su versión?"}
                        <SegTooltip text="Encontrala en la cédula verde." />
                    </legend>
                    <div className="d-flex justify-content-center">
                        <div className="col-10 col-sm-8 col-md-5 col-lg-3">
                            {(versiones) ?
                                <FormProvider {...methods}>
                                    {versiones && (
                                        <SegSelect
                                            options={versiones}
                                            width={"100%"}
                                            height={(window.innerHeight <= 700) ? "sm" : (window.innerHeight <= 910) ? "md" : "lg"}
                                            selected={versionSelected}
                                            setSelected={setVersionSelected}
                                            name="version"
                                            handleSubmit={handleSubmit}
                                            hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                                            focusBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                                            empresaColor={config.origen !== "SEGURARSE" ? {
                                                selected: config.colors.accent,
                                                hover: config.colors.tertiary
                                            } : undefined}
                                        />
                                    )}
                                </FormProvider>
                                :
                                <div className='d-flex justify-content-center'>
                                    <div className="spinner-border text-center" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}