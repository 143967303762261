import { Route, Routes, useParams } from "react-router-dom";
import { Cotizador as CotizadorAuto } from "../../Pages/Auto/Cotizador";
import { Cotizador as CotizadorMoto } from "../../Pages/Moto/Cotizador";
import { Cotizador as CotizadorArt } from "../../Pages/ART/Cotizador"
import { Cotizador as CotizadorCaucion } from "../../Pages/Caucion/Cotizador"
import { Cotizador as CotizadorPrepaga } from "../../Pages/Prepaga/Individuo/Cotizador"
import { Cotizador as CotizadorPrepagaCorpo } from "../../Pages/Prepaga/Corporativo/Cotizador"
import { Cotizador as CotizadorAP } from "../../Pages/AP/Cotizador"
import { Precios as PreciosAuto } from "../../Pages/Auto/Precios";
import { Precios as PreciosMoto} from "../../Pages/Moto/Precios";
import { Institucional } from "../../Pages/Institucional/Institucional";
import { NotFound } from "../../Pages/404/NotFound";
import { GraciasAuto } from "../../Pages/Gracias/GraciasAuto";
import { GraciasAutoEmision } from "../../Pages/Gracias/GraciasEmision";
import { Emision } from "../../Pages/Auto/Emision";
import { SolicitudEnviada } from "../../Components/Slides/Auto/Emision/SolicitudEnviada";
import { CertificadoNoRodamiento } from "../../Components/Slides/Auto/Emision/CertificadoNoRodamiento";
import { ErrorEmision } from "../../Pages/404/ErrorEmision";
import { SolicitudEnviada2 } from "../../Components/Slides/Auto/Emision/SolicitudEnviada2";
import { GraciasArt } from "../../Pages/Gracias/GraciasArt";
import { GraciasCaucion } from "../../Pages/Gracias/GraciasCaucion";
import { GraciasCorporativoPrepaga } from "../../Pages/Gracias/GraciasCorporativoPrepaga";
import { Precios as PreciosPrepaga } from "../../Pages/Prepaga/Individuo/Precios";
import { Precios as PreciosAccidentesPersonales } from "../../Pages/AP/Precios";
import { Cotizador as MultiCotizador } from "../../Pages/Multicotizador/Cotizador"
import { Emision as EmisionAP } from "../../Pages/AP/Emision";

import { GraciasIndividuoPrepaga } from "../../Pages/Gracias/GraciasIndividuoPrepaga";
import { useEffect } from "react";
import { Pymes } from "../../Pages/Landings/Pages/Pymes";
import { Art } from "../../Pages/Landings/Pages/Art";
import { Caucion } from "../../Pages/Landings/Pages/Caucion";
import { Home } from "../../Pages/Landings/Pages/Home";
import { Contacto } from "../../Pages/Landings/Pages/Contacto";
import { Sustentabilidad } from "../../Pages/Landings/Pages/Sustentabilidad";
import { QuienesSomos } from "../../Pages/Landings/Pages/QuienesSomos";
import { OneTeam } from "../../Pages/Landings/Pages/OneTeam";
import { PoliticasPrivacidad } from "../../Pages/Landings/Pages/PoliticasPrivacidad";
import { IntegralComercio } from "../../Pages/Landings/Pages/IntegralComercio";
import { ResponsabilidadCivil } from "../../Pages/Landings/Pages/ResponsabilidadCivil";
import { FlotaVehiculos } from "../../Pages/Landings/Pages/FlotaVehiculos";
import { IncendioCorporativo } from "../../Pages/Landings/Pages/IncendioCorporativo";
import { MalaPraxis } from "../../Pages/Landings/Pages/MalaPraxis";
import { RCMedioambiental } from "../../Pages/Landings/Pages/RCMedioambiental";
import { TodoRiesgoCorporativo } from "../../Pages/Landings/Pages/TodoRiesgoCorporativo";
import { Construccion } from "../../Pages/Landings/Pages/Construccion";
import { Transporte } from "../../Pages/Landings/Pages/Transporte";
import { Consorcio } from "../../Pages/Landings/Pages/Consorcio";
import { Credito } from "../../Pages/Landings/Pages/Credito";
import { Agro } from "../../Pages/Landings/Pages/Agro";
import { VidaCorporativa } from "../../Pages/Landings/Pages/VidaCorporativa";
import { PreguntasFrecuentes } from "../../Pages/Landings/Pages/PreguntasFrecuentes";
import { FormularioArrepentimiento } from "../../Pages/Landings/Pages/FormularioArrepentimiento";
import { FormularioArrepentimientoEnviado } from "../../Pages/Landings/Pages/FormularioArrepentimientoEnviado";
import { Individuos } from "../../Pages/Landings/Pages/Individuos";
import { Auto } from "../../Pages/Landings/Pages/Auto";
import { Moto } from "../../Pages/Landings/Pages/Moto";
import { Celular } from "../../Pages/Landings/Pages/Celular";
import { Prepaga } from "../../Pages/Landings/Pages/Prepaga";
import { Embarcaciones } from "../../Pages/Landings/Pages/Embarcaciones";
import { BolsoProtegido } from "../../Pages/Landings/Pages/BolsoProtegido";
import { AP } from "../../Pages/Landings/Pages/AP";
import { Hogar } from "../../Pages/Landings/Pages/Hogar";
import { SolicitudEnviada as CertificadoEmisionAP } from "../../Components/Slides/AP/emision/SolicitudEnviada";
import { AsistenciaAlViajero } from "../../Pages/Landings/Pages/AsistenciaAlViajero";
import { GraciasAccidentesPersonales } from "../../Pages/Gracias/GraciasAP";
import { Siniestros } from "../../Pages/Formularios/Siniestros/Siniestros";

export const AppRouter = () => {
  const autoBaseURL = "/cotizador-de-seguros-auto"
  const artBaseURL = "/cotizador-de-seguros-art"
  const caucionBaseURL = "/cotizador-de-seguros-pymes-caucion"
  const prepagaBaseURL = "/cotizador-de-seguros-salud"
  const prepagaCorpoBaseURL = "/cotizador-de-seguros-salud-corporativo"
  const apBaseURL = "/cotizador-de-seguros-ap"
  const motosURL = "/cotizador-de-seguros-moto"


  const Redirect = () => {
    const params = useParams();

    useEffect(() => {
      if (params['*'] === '') {
        window.location.reload();
      } else {
        window.location.href = window.location.origin;
      }
    }, [])

    return <></>
  }


  return (
    <Routes>
      {/* Friendly URLs */}
      <Route path="/:compania-cotizador" element={<CotizadorAuto step={0} subStep={1} />} />
      <Route path="/comentarios-de-clientes" element={<CotizadorAuto step={0} subStep={1} />} />
      <Route path="/seguros-de-auto-cotizador" element={<CotizadorAuto step={0} subStep={1} />} />
      <Route path="/resultado-del-cotizador-de-autos" element={<PreciosAuto />} />
      <Route path="/resultado-del-cotizador-de-seguros-auto" element={<PreciosAuto />} />
      <Route path='/:rubro/error' element={<NotFound />} />

      {/* Auto Cotizacion */}
      <Route path={autoBaseURL} element={<CotizadorAuto step={0} subStep={1} />} />
      <Route path="/auto/cotizacion/:compania?/patente" element={<CotizadorAuto step={0} subStep={1} />} />
      <Route path="/auto/cotizacion/:compania?/resultado-patente" element={<CotizadorAuto step={0} subStep={2} />} />
      <Route path="/auto/cotizacion/:compania?/marca" element={<CotizadorAuto step={1} subStep={1} />} />
      <Route path="/auto/cotizacion/:compania?/modelo" element={<CotizadorAuto step={1} subStep={2} />} />
      <Route path="/auto/cotizacion/:compania?/anio" element={<CotizadorAuto step={1} subStep={3} />} />
      <Route path="/auto/cotizacion/:compania?/version" element={<CotizadorAuto step={1} subStep={4} />} />
      <Route path="/auto/cotizacion/:compania?/nombre" element={<CotizadorAuto step={1} subStep={5} />} />
      <Route path="/auto/cotizacion/:compania?/apellido" element={<CotizadorAuto step={1} subStep={6} />} />
      <Route path="/auto/cotizacion/:compania?/email" element={<CotizadorAuto step={1} subStep={7} />} />
      <Route path="/auto/cotizacion/:compania?/telefono" element={<CotizadorAuto step={1} subStep={8} />} />
      <Route path="/auto/cotizacion/:compania?/fecha-nacimiento" element={<CotizadorAuto step={1} subStep={9} />} />
      <Route path="/auto/cotizacion/:compania?/genero" element={<CotizadorAuto step={1} subStep={10} />} />
      <Route path="/auto/cotizacion/:compania?/codigo-postal" element={<CotizadorAuto step={1} subStep={11} />} />
      <Route path="/auto/precios/:compania?" element={<PreciosAuto />} />
      <Route path="/auto/gracias/:compania?" element={<GraciasAutoEmision />} />
      <Route path="/auto/gracias-alt/:compania?" element={<GraciasAuto />} />
      {/* <Route path='/auto/error' element={<NotFound />} /> */}
      <Route path='*' element={<Redirect />} />

      {/* Emision Auto */}
      <Route path="/auto/emision/revision" element={<Emision step={0} subStep={1} />} />
      <Route path="/auto/emision/datospersonales" element={<Emision step={1} subStep={1} />} />
      <Route path="/auto/emision/radicacionvehiculo" element={<Emision step={1} subStep={2} />} />
      <Route path="/auto/emision/datosauto" element={<Emision step={1} subStep={3} />} />
      <Route path="/auto/emision/datostarjeta" element={<Emision step={1} subStep={4} />} />
      <Route path="/auto/emision/solicitudenviada" element={<SolicitudEnviada2 />} />
      <Route path="/auto/emision/solicitudenviada2" element={<SolicitudEnviada />} />
      <Route path="/auto/emision/certificadoNoRodamiento" element={<CertificadoNoRodamiento />} />
      <Route path="/auto/emision/error" element={<ErrorEmision />} />

      {/* ART Cotizacion */}
      <Route path={artBaseURL} element={<CotizadorArt step={0} subStep={1} />} />
      <Route path="/art/cotizacion/:compania?/empleados" element={<CotizadorArt step={0} subStep={2} />} />
      <Route path="/art/cotizacion/:compania?/masa-salarial" element={<CotizadorArt step={0} subStep={3} />} />
      <Route path="/art/cotizacion/:compania?/nombre-y-apellido" element={<CotizadorArt step={1} subStep={1} />} />
      <Route path="/art/cotizacion/:compania?/email" element={<CotizadorArt step={1} subStep={2} />} />
      <Route path="/art/cotizacion/:compania?/telefono" element={<CotizadorArt step={1} subStep={3} />} />
      <Route path="/art/cotizacion/:compania?/codigo-postal" element={<CotizadorArt step={1} subStep={4} />} />
      <Route path="/resultado-del-cotizador-de-art/:compania?" element={<GraciasArt />} />

      {/* Caucíon Cotizacion */}
      <Route path={caucionBaseURL} element={<CotizadorCaucion step={0} subStep={1} />} />
      <Route path="/caucion/cotizacion/:compania?/nombre-y-apellido" element={<CotizadorCaucion step={0} subStep={2} />} />
      <Route path="/caucion/cotizacion/:compania?/cuit-y-razon-social" element={<CotizadorCaucion step={0} subStep={3} />} />
      <Route path="/caucion/cotizacion/:compania?/telefono" element={<CotizadorCaucion step={0} subStep={4} />} />
      <Route path="/caucion/cotizacion/:compania?/email" element={<CotizadorCaucion step={0} subStep={5} />} />
      <Route path="/caucion/cotizacion/:compania?/codigo-postal" element={<CotizadorCaucion step={1} subStep={1} />} />
      <Route path="/caucion/cotizacion/:compania?/cobertura-y-moneda" element={<CotizadorCaucion step={1} subStep={2} />} />
      <Route path="/resultado-del-cotizador-de-caucion/:compania?" element={<GraciasCaucion />} />

      {/* Prepaga Cotizacion */}
      <Route path={prepagaBaseURL} element={<CotizadorPrepaga step={0} subStep={1} />} />
      <Route path={prepagaCorpoBaseURL} element={<CotizadorPrepaga step={0} subStep={1} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/nombre-y-apellido" element={<CotizadorPrepaga step={0} subStep={2} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/email" element={<CotizadorPrepaga step={0} subStep={3} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/telefono" element={<CotizadorPrepaga step={0} subStep={4} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/localidad-provincia" element={<CotizadorPrepaga step={0} subStep={5} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/sueldo-bruto" element={<CotizadorPrepaga step={0} subStep={7} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/observaciones" element={<CotizadorPrepaga step={1} subStep={1} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/documento-conyuge" element={<CotizadorPrepaga step={1} subStep={2} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/fecha-nacimiento" element={<CotizadorPrepaga step={0} subStep={6} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/sueldo-bruto-conyuge" element={<CotizadorPrepaga step={1} subStep={3} />} />
      <Route path="/prepaga/individuo/cotizacion/:compania?/hijos" element={<CotizadorPrepaga step={1} subStep={4} />} />
      <Route path="/resultado-del-cotizador-de-salud/:compania?/gracias" element={<GraciasIndividuoPrepaga />} />

      <Route path="/resultado-del-cotizador-de-salud" element={<PreciosPrepaga />} />

      {/* Prepaga Corporativo Cotizacion*/}
      <Route path="/prepaga/corporativo/cotizacion/:compania?/razon-social" element={<CotizadorPrepagaCorpo step={0} subStep={2} />} />
      <Route path="/prepaga/corporativo/cotizacion/:compania?/empleados" element={<CotizadorPrepagaCorpo step={0} subStep={3} />} />
      <Route path="/prepaga/corporativo/cotizacion/:compania?/email" element={<CotizadorPrepagaCorpo step={1} subStep={1} />} />
      <Route path="/prepaga/corporativo/cotizacion/:compania?/telefono-celular" element={<CotizadorPrepagaCorpo step={1} subStep={2} />} />
      <Route path="/prepaga/corporativo/cotizacion/:compania?/localidad-provincia" element={<CotizadorPrepagaCorpo step={1} subStep={3} />} />
      <Route path="/resultado-del-cotizador-de-salud-corporativo/:compania?" element={<GraciasCorporativoPrepaga />} />

      {/*Multicotizador */}
      {/*<Route path="/cotizador-de-seguros-celular" element={<MultiCotizador rubro={"celular"} />} />*/}
      {/*<Route path="/resultado-del-cotizador-de-celular" element={<MultiCotizador rubro={"celular"} resultados={true} />} />*/}
      {/*<Route path="/cotizador-de-seguros-integral-de-comercio" element={<MultiCotizador rubro={"integral-de-comercio"} />} />
      <Route path="/resultado-del-cotizador-de-integral-de-comercio" element={<MultiCotizador rubro={"integral-de-comercio"} resultados={true} />} />
      <Route path="/:rubroURL/" element={<MultiCotizador />} />
      <Route path="/:rubroURL/cotizacion/:tipoPersona?">
        <Route index element={<MultiCotizador />} />
        <Route path=":stepId/:compania?" element={<MultiCotizador />} />
      </Route>*/}


      {/*Landings BASE */}
      <Route path="/" element={<Home />} />
      <Route path="/sustentabilidad" element={<Sustentabilidad />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path="/preguntas-frecuentes" element={<PreguntasFrecuentes />} />
      <Route path="/politicas-privacidad" element={<PoliticasPrivacidad />} />
      <Route path="/formulario-arrepentimiento" element={<FormularioArrepentimiento />} />
      <Route path="/quienes-somos" element={<QuienesSomos />} />
      <Route path="/nuestro-oneteam" element={<OneTeam />} />

      {/*Landings Pymes */}
      <Route path="/seguros-corporativos" element={<Pymes />} />
      <Route path="/seguros-corporativos-ART" element={<Art />} />
      <Route path="/seguros-corporativos-integral-comercio" element={<IntegralComercio />} />
      <Route path="/seguros-corporativos-caucion" element={<Caucion />} />
      <Route path="/seguros-corporativos-RC" element={<ResponsabilidadCivil />} />
      <Route path="/seguros-corporativos-flota" element={<FlotaVehiculos />} />
      <Route path="/seguros-corporativos-incendio" element={<IncendioCorporativo />} />
      <Route path="/seguros-corporativos-mala-praxis" element={<MalaPraxis />} />
      <Route path="/seguros-corporativos-RC-medioambiental" element={<RCMedioambiental />} />
      <Route path="/seguros-corporativos-todo-riesgo" element={<TodoRiesgoCorporativo />} />
      <Route path="/seguros-corporativos-construccion" element={<Construccion />} />
      <Route path="/seguros-corporativos-transporte" element={<Transporte />} />
      <Route path="/seguros-corporativos-consorcio" element={<Consorcio />} />
      <Route path="/seguros-corporativos-credito" element={<Credito />} />
      <Route path="/seguros-corporativos-agro" element={<Agro />} />
      <Route path="/seguros-corporativos-vida" element={<VidaCorporativa />} />

      <Route path="/formulario-arrepentimiento-enviado" element={<FormularioArrepentimientoEnviado />} />

      {/*Landings Individuos */}
      <Route path="/seguros-individuos" element={<Individuos />} />
      <Route path="/seguros-individuos-auto" element={<Auto />} />
      <Route path="/seguros-individuos-prepaga" element={<Prepaga />} />
      <Route path="/seguros-individuos-moto" element={<Moto />} />
      <Route path="/seguros-individuos-asistencia" element={<AsistenciaAlViajero />} />
      <Route path="/seguros-individuos-hogar" element={<Hogar />} />
      <Route path="/seguros-individuos-AP" element={<AP />} />
      <Route path="/seguros-individuos-bolso" element={<BolsoProtegido />} />
      <Route path="/seguros-individuos-celular" element={<Celular />} />
      <Route path="/seguros-individuos-embarcacion" element={<Embarcaciones />} />

      {/* Accidentes personales Cotizacion*/}

      <Route path={apBaseURL} element={<CotizadorAP step={0} subStep={1} />} />
      <Route path="/ap/cotizacion/:compania?/tipo-persona" element={<CotizadorAP step={0} subStep={2} />} />
      <Route path="/ap/individuo/cotizacion/:compania?/nombre-y-apellido" element={<CotizadorAP step={0} subStep={3} />} />
      <Route path="/ap/individuo/cotizacion/:compania?/telefono" element={<CotizadorAP step={0} subStep={4} />} />
      <Route path="/ap/individuo/cotizacion/:compania?/email" element={<CotizadorAP step={0} subStep={5} />} />
      <Route path="/ap/cotizacion/:compania?/localidad-provincia" element={<CotizadorAP step={1} subStep={1} />} />
      <Route path="/ap/cotizacion/:compania?/condicion-iva" element={<CotizadorAP step={1} subStep={2} />} />
      <Route path="/ap/cotizacion/:compania?/datos-asegurado" element={<CotizadorAP step={1} subStep={3} />} />
      <Route path="/ap/cotizacion/:compania?/datos-cobertura" element={<CotizadorAP step={1} subStep={4} />} />
      <Route path="/resultado-cotizador-ap/:compania?" element={<PreciosAccidentesPersonales />} />
      <Route path="/ap/cotizacion/:compania?/gracias" element={<GraciasAccidentesPersonales />} />

      {/* Accidentes personales Emision*/}
      <Route path="/ap/emision/:compania?" element={<EmisionAP step={0} subStep={1} />} />
      <Route path="/ap/emision/:compania?/datos-asegurado" element={<EmisionAP step={0} subStep={2} />} />
      <Route path="/ap/emision/:compania?/datos-beneficiario" element={<EmisionAP step={0} subStep={3} />} />
      <Route path="/ap/emision/:compania?/datos-tarjeta" element={<EmisionAP step={1} subStep={1} />} />
      <Route path="/ap/emision/:compania?/solicitudenviada" element={<CertificadoEmisionAP />} />

      {/*Multicotizador */}
      <Route path="/cotizador-de-seguros-celular" element={<MultiCotizador rubro={"celular"} />} />
      <Route path="/resultado-del-cotizador-de-celular" element={<MultiCotizador rubro={"celular"} resultados={true} />} />
      <Route path="/cotizador-de-seguros-integral-de-comercio" element={<MultiCotizador rubro={"integral-de-comercio"} />} />
      <Route path="/resultado-del-cotizador-de-integral-de-comercio" element={<MultiCotizador rubro={"integral-de-comercio"} resultados={true} />} />
      <Route path="/:rubroURL/" element={<MultiCotizador />} />
      <Route path="/:rubroURL/cotizacion/:tipoPersona?">
        <Route index element={<MultiCotizador />} />
        <Route path=":stepId/:compania?" element={<MultiCotizador />} />
      </Route>

      {/*Formularios */}
      <Route path="/siniestros/vehiculos" element={<Siniestros step={0} subStep={0}/>} />
      <Route path="/siniestros/vehiculos/tipo-siniestro" element={<Siniestros step={1} subStep={0}/>} />
      <Route path="/siniestros/vehiculos/formulario" element={<Siniestros step={2} subStep={0}/>} />
      {/* Moto Cotizacion */}
      <Route path={motosURL} element={<CotizadorMoto step={1} subStep={1} />} />
      <Route path="/moto/cotizacion/:compania?/patente" element={<CotizadorMoto step={0} subStep={1} />} />
      <Route path="/moto/cotizacion/:compania?/resultado-patente" element={<CotizadorMoto step={0} subStep={2} />} />
      <Route path="/moto/cotizacion/:compania?/marca" element={<CotizadorMoto step={1} subStep={1} />} />
      <Route path="/moto/cotizacion/:compania?/modelo" element={<CotizadorMoto step={1} subStep={2} />} />
      <Route path="/moto/cotizacion/:compania?/anio" element={<CotizadorMoto step={1} subStep={3} />} />
      <Route path="/moto/cotizacion/:compania?/version" element={<CotizadorMoto step={1} subStep={4} />} />
      <Route path="/moto/cotizacion/:compania?/nombre" element={<CotizadorMoto step={1} subStep={5} />} />
      <Route path="/moto/cotizacion/:compania?/apellido" element={<CotizadorMoto step={1} subStep={6} />} />
      <Route path="/moto/cotizacion/:compania?/email" element={<CotizadorMoto step={1} subStep={7} />} />
      <Route path="/moto/cotizacion/:compania?/telefono" element={<CotizadorMoto step={1} subStep={8} />} />
      <Route path="/moto/cotizacion/:compania?/fecha-nacimiento" element={<CotizadorMoto step={1} subStep={9} />} />
      <Route path="/moto/cotizacion/:compania?/genero" element={<CotizadorMoto step={1} subStep={10} />} />
      <Route path="/moto/cotizacion/:compania?/codigo-postal" element={<CotizadorMoto step={1} subStep={11} />} />
      <Route path="/resultado-del-cotizador-de-moto" element={<PreciosMoto />} />
      <Route path="/moto/precios/:compania?" element={<PreciosMoto />} />
      <Route path="/moto/gracias/:compania?" element={<GraciasAutoEmision esMoto/>} />
      <Route path="/moto/gracias-alt/:compania?" element={<GraciasAuto esMoto/>} />


      {process.env.REACT_APP_DEV_MODE === "true" ? (
        <>
          <Route path="/" element={<Institucional />} />
        </>
      ) : <></>}

    </Routes >
  );
};