import chevrolet from "../Assets/img/logos/autos/logo_chevrolet.svg";
import fiat from "../Assets/img/logos/autos/logo_fiat.svg";
import citroen from "../Assets/img/logos/autos/logo_citroen.svg";
import ford from "../Assets/img/logos/autos/logo_ford.svg";
import honda from "../Assets/img/logos/autos/logo_honda.svg";
import nissan from "../Assets/img/logos/autos/logo_nissan.svg";
import peugeot from "../Assets/img/logos/autos/logo_peugeot.svg";
import renault from "../Assets/img/logos/autos/logo_renault.svg";
import toyota from "../Assets/img/logos/autos/logo_toyota.svg";
import volkswagen from "../Assets/img/logos/autos/logo_volkswagen.svg";
import segurarse from "../Assets/img/logo_segurarse.svg";
import WhiteSegurarse from "../Assets/img/logo_segurarse_blanco.svg";
import Garex from '../Assets/img/logos/aseguradoras/logo_garex_assist.png';

import bajaj from "../Assets/img/logos/motos/logo-bajaj.png";
import corven from "../Assets/img/logos/motos/logo-corven.png";
import gilera from "../Assets/img/logos/motos/logo-gilera.png";
import kawasaki from "../Assets/img/logos/motos/logo-kawasaki.png";
import mondial from "../Assets/img/logos/motos/logo-mondial.png";
import motomel from "../Assets/img/logos/motos/logo-motomel.png";
import zanella from "../Assets/img/logos/motos/logo-zanella.png";
import suzuki from "../Assets/img/logos/motos/logo-suzuki.png";
import yamaha from "../Assets/img/logos/motos/logo-yamaha.png";

export interface ImageMarca {
  marca: string
  image: string
}

export const images: ImageMarca[] = [
  {
    marca: "volkswagen",
    image: volkswagen,
  },
  {
    marca: "renault",
    image: renault,
  },
  {
    marca: "chevrolet",
    image: chevrolet,
  },
  {
    marca: "ford",
    image: ford,
  },
  {
    marca: "fiat",
    image: fiat,
  },
  {
    marca: "peugeot",
    image: peugeot,
  },
  {
    marca: "toyota",
    image: toyota,
  },
  {
    marca: "citroen",
    image: citroen,
  },
  {
    marca: "honda",
    image: honda,
  },
  {
    marca: "nissan",
    image: nissan,
  },
];

export const logos_motos = [
  {
    marca: "bajaj",
    image: bajaj,
  },
  {
    marca: "corven",
    image: corven,
  },
  {
    marca: "honda",
    image: honda,
  },
  {
    marca: "gilera",
    image: gilera,
  },
  {
    marca: "kawasaki",
    image: kawasaki,
  },
  {
    marca: "mondial",
    image: mondial,
  },
  {
    marca: "motomel",
    image: motomel,
  },
  {
    marca: "zanella",
    image: zanella,
  },
  {
    marca: "suzuki",
    image: suzuki,
  },
  {
    marca: "yamaha",
    image: yamaha,
  },
]
export const logosImage = [
  {
    image: segurarse,
  },
  {
    image: WhiteSegurarse,
  },
  {
    image: Garex
  }
];
