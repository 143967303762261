import { AxiosError } from 'axios';
const DEFAULT_ATTEMPT: number = 2;

export async function retryRequest<T>(
  axiosRequest: () => Promise<T>,
  retries: number = DEFAULT_ATTEMPT
): Promise<T> {
  let attempt = 0;

  while (attempt <= retries) {
    try {
      const response = await axiosRequest();
      return response; // Si la solicitud tiene éxito, retorna el resultado
    } catch (error: any) {
      attempt++;
      if (attempt > retries || !isRetryableError(error)) {
        throw error; // Si se alcanzan los reintentos, lanza el error
      }
    }
  }

  throw new Error('Max retries reached'); // Solo por seguridad, aunque no debería llegar aquí
}

function isRetryableError(error: AxiosError): boolean {
  // Puedes definir qué errores son "reintetables"
  return error.response?.status === 500 || error.response?.status === 503;
}


export const getPathName = (base_endpoint = true): string => {

  const baseUrl = window.location.origin;

  if (process.env.REACT_APP_DEV_MODE === "true" || window.location.hostname === "localhost") {
    return process.env.REACT_APP_LOCAL_HOSTNAME + "" + (base_endpoint ? process.env.REACT_APP_ENDPOINT : "");
  }

  return baseUrl + (base_endpoint ? process.env.REACT_APP_ENDPOINT : "");
}

/**
 * Convierte un objeto Date en una cadena de texto con el formato `DD/MM/YYYY`.
 *
 * @param {Date} date - La fecha que se va a formatear.
 * @returns {string} La fecha formateada como `DD/MM/YYYY`.
 *
 * @example
 * const date = new Date('2024-08-02T00:00:00');
 * const formattedDate = formatDate(date);
 * console.log(formattedDate); // "02/08/2024"
 */
export function formatDate(date: Date) {
  const day = date.getDate().toString().padStart(2, '0'); 
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

/**
* Convierte un objeto Date en una cadena de texto con el formato `HH : mm`.
*
* @param {Date} date - La fecha que se va a formatear.
* @returns {string} La hora formateada como `HH : mm`.
*
* @example
* const date = new Date('2024-08-02T10:58:00');
* const formattedTime = formatHora(date);
* console.log(formattedTime); // "10 : 58"
*/
export function formatHora(date: Date): string {
  const hours = date.getHours().toString().padStart(2, '0'); // Asegura que las horas tengan dos dígitos
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Asegura que los minutos tengan dos dígitos

  return `${hours} : ${minutes}`;
}