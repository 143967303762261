import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { SegHeaderLogo } from '../../../Components/SegUI/SegHeaderLogo/SegHeaderLogo';
import { SegStepper } from '../../../Components/SegUI/SegStepper/SegStepper';
import segurarse from "../../../Assets/img/logo_segurarse.svg";
import { UbicacionSiniestro } from './Steps/UbicacionSiniestro';
import { TipoSiniestro } from '../Siniestros/Steps/TipoSiniestro';
import useSEOConfig from '../../../hooks/seo/useSEOConfig';
import { SegBackButton } from '../../../Components/SegUI/SegBackButton/SegBackButton';
import { useSiniestroStore } from '../../../Services/Providers/SiniestroStore';
import { AccidenteTransito } from './Steps/DatosSiniestro/AccidenteTransito';
import { RoboParcial } from './Steps/DatosSiniestro/RoboParcial';
import { DañoParcial } from './Steps/DatosSiniestro/DañoParcial';
import { RoturaCristalesCerraduras } from './Steps/DatosSiniestro/RoturaCristalesCerraduras';
import { RoboTotal } from './Steps/DatosSiniestro/RoboTotal';
import obtenerDatosPoliza from '../../../Services/API/DatosPoliza';
import obtenerParametros from '../../../Services/API/Parametros';
import { EmpresaProps, useConfiguracionEmpresa } from '../../../Services/Providers/useConfiguracionEmpresa';

const Loading = styled.section`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #fff;
`;

export interface IPasos {
    step: number;
    subStep: number;
}

interface IStep {
    setSteps: (value: IPasos) => void;
    config: EmpresaProps;
    isDisabled?: boolean;
}

export type StepComponentProps = IStep;

export interface SiniestrosProps {
    step?: number;
    subStep?: number;

}

const PageContainer = styled.div`
  display: flex;  
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
`;

export const Siniestros = ({ step = 0, subStep = 0 }: SiniestrosProps) => {
    const nav = useNavigate();
    const [steps, setSteps] = useState<IPasos>({ step, subStep });
    const [loading, setLoading] = useState(false);
    const [loadingParams, setLoadingParams] = useState<boolean>(false);
    const { siniestroValues, setSiniestroValues } = useSiniestroStore();
    const { origen, config, setConfig } = useConfiguracionEmpresa();
    const [searchParams] = useSearchParams();
    const paramFormValue = searchParams.get("form");
    const [isFormInvalid, setIsFormInvalid] = useState<boolean>(false);

    useSEOConfig({
        title: "Denuncia de Siniestro",
    });

    useEffect(() => {
        getParams();
    }, []);

    useEffect(() => {
        if (paramFormValue) {
            const getData = async () => {
                setLoading(true);
                const data = await obtenerDatosPoliza(encodeURIComponent(paramFormValue))
                if (data.DatosFormulario.Estado !== "Vigente") {
                    setIsFormInvalid(true);
                    setTimeout( () => {
                        return nav("/");
                    },1500)
                }
                setSiniestroValues({
                    ...siniestroValues,
                    poliza: {
                        AccountId: data.AccountId,
                        PolizaId: data.PolizaId,
                        Compania: data.DatosFormulario.Compania,
                        NombreApellido: data.DatosFormulario.NombreApellido,
                        NumeroPoliza: data.DatosFormulario.NumeroPoliza,
                        RiesgoId: data.RiesgoId || null
                    }
                })
                setLoading(false)
            }
            getData();
        }
    }, [searchParams])

    useEffect(() => {
        setSteps((prev) => ({
            ...prev,
            step: step,
            subStep: subStep,
        }));
    }, [step, subStep]);


    const getParams = async () => {
        setLoadingParams(true);
        const response: any = await obtenerParametros({ rubro: "AUTO" });
        if (response) {
            const estilos = response.data.estilos;
            if (estilos) {
                setConfig({
                    ...JSON.parse(estilos),
                    origen: response.data.origen
                }, response.data.origen)
            } else {
                setConfig({
                    origen: response.data.origen,
                    images: {
                        logo: "",
                    },
                    colors: {
                        accent: "",
                        primary: "",
                        secondary: "",
                        tertiary: "",
                        icons: "",
                        navbar: "",
                        segu_stepper: ""
                    },
                    config: {
                        cookies: true
                    }
                }, response.data.origen)
            }
            setLoadingParams(false);
        }
    }


    const renderVista = () => {
        switch (steps.step) {
            case 0:
                switch (steps.subStep) {
                    case 0:
                        return <UbicacionSiniestro setSteps={setSteps} config={config} isDisabled={isFormInvalid} />;
                    default:
                        return <div>Substep no definido para Tipo de Siniestro 1</div>;
                }
            case 1:
                switch (steps.subStep) {
                    case 0:
                        return <TipoSiniestro setSteps={setSteps} config={config} />;
                    default:
                        return <div>Substep no definido para Tipo de Siniestro 2</div>;
                }
            case 2:
                switch (siniestroValues.tipoSiniestro) {
                    case 'AccidenteTransito':
                        return <AccidenteTransito setSteps={setSteps} config={config} />;
                    case 'RoturaCristalesCerraduras':
                        return <RoturaCristalesCerraduras setSteps={setSteps} config={config} />;
                    case 'RoboTotal':
                        return <RoboTotal setSteps={setSteps} config={config} />;
                    case 'RoboParcial':
                        return <RoboParcial setSteps={setSteps} config={config} />;
                    case 'DanioParcial':
                        return <DañoParcial setSteps={setSteps} config={config} />;
                    default:
                        return <div>El tipo siniestro no esta definido</div>;
                }
            default:
                return <div>Error: Paso no encontrado</div>;
        }
    };

    if (loadingParams) {
        return (
            <Loading>
                <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                    <p className=''><strong>Cargando</strong></p>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </Loading>
        )
    }

    return (
        <PageContainer>
            <div className="sticky-top"
                style={{ background: origen !== "SEGURARSE" ? config.colors.navbar : "#ffffff" }}
            >
                <div className="container pt-3 pb-3">
                    {
                        config.origen !== "SEGURARSE" ?
                            <div className="row">
                                <div className="col-md-6 offset-md-3 text-center d-flex justify-content-center gap-3">
                                    {" "}
                                    {/* Centra el contenido en la columna */}
                                    <SegHeaderLogo
                                        img={config.images.logo}
                                        alt="Logo Segurarse"
                                        width={window.innerWidth > 768 ? "130px" : "100px"}
                                        style={{ filter: "brightness(0) invert(1)" }}
                                    />
                                    <div style={{ height: "100%", width: "2px", backgroundColor: "#ffffff" }}></div>
                                    <SegHeaderLogo
                                        img={segurarse}
                                        alt="Logo Segurarse"
                                        width={window.innerWidth > 768 ? "140px" : "110px"}
                                        style={{ filter: "brightness(0) invert(1)" }}
                                    />
                                </div>

                            </div>
                            :
                            <div className="row">
                                <div className="col-md-6 offset-md-3 text-center">
                                    {" "}
                                    {/* Centra el contenido en la columna */}
                                    <SegHeaderLogo
                                        img={segurarse}
                                        alt="Logo Segurarse"
                                        width="160px"
                                    />
                                </div>
                            </div>
                    }
                </div>
            </div>

            <div>
                <SegStepper
                    icon="auto"
                    label="Denuncia tu siniestro"
                    stepActive={(steps.step + 1) as 1 | 2}
                    isStepper={true}
                    finalStep='3'
                    bgColor={origen !== "SEGURARSE" ? config.colors.segu_stepper : undefined}
                    iconColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                    lineColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                    labelColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                    stepInactiveBgColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                    stepActiveBorderColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                    stepActiveTextColor={origen !== "SEGURARSE" ? config.colors.icons : undefined}
                />
            </div>

            {loading ? (
                <div className="d-flex justify-content-center py-5">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <Content>
                    <div className="alert alert-secondary mb-0" role="alert">
                        {siniestroValues.poliza &&
                            <p className={`text-center m-0 p-0 ${window.innerWidth < 778 ? "fs-14" : "fs-16"}`} >
                                <span className='mx-2 d-block d-sm-inline-block text-center'><b>Nombre: </b>{siniestroValues.poliza.NombreApellido}</span>
                                <span className='mx-2 d-block d-sm-inline-block text-center'><b>Poliza: </b>{siniestroValues.poliza.NumeroPoliza}</span>
                                <span className='mx-2 d-block d-sm-inline-block text-center'><b>Compañia: </b>{siniestroValues.poliza.Compania}</span>
                            </p>
                        }
                    </div>
                    {isFormInvalid &&
                        <div className="alert alert-danger text-center" role="alert">
                            El reisgo no se encuentra <b>vigente</b>!
                        </div>
                    }
                    {steps.step > 0 && (
                        <div className="cot-bc">
                            <div className="container">
                                <SegBackButton
                                    onClick={() => { nav(-1) }}
                                    marca={""}
                                    modelo={""}
                                    version=''
                                    anio='' />
                            </div>
                        </div>

                    )}
                    <div className="container">
                        {renderVista()}
                    </div>
                </Content>
            )}
        </PageContainer>
    );
};
