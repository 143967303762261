import Axios from 'axios'
import { getPathName } from './helper';

const obtenerCodigoPostalPorLocalidad = async(localidad:string) => {
    try {
        const {data} = await Axios.get(getPathName(false) + `/CodigosPostales/AsyncCodigoPostalByLocalidadP?Localidad=${localidad}`);
        if(data){
            return {
                ok: true,
                cod: data
            };
        }
        throw Error("No se encontró la localidad")
    } catch (error) {
        return {
            ok: false,
            msg: error
        }
    }

}

export default obtenerCodigoPostalPorLocalidad;