import { SegButton } from '../../../../Components/SegUI/SegButton/SegButton';
import { StepComponentProps } from '../Siniestros';
import { TipoSiniestro as TypeTipoSiniestro, useSiniestroStore } from '../../../../Services/Providers/SiniestroStore';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const TipoSiniestro = ({ setSteps, config }: StepComponentProps) => {

  const { siniestroValues, setSiniestroValues } = useSiniestroStore();
  const nav = useNavigate();


  useEffect(() => {
    if (!siniestroValues.fecha ||
      !siniestroValues.hora ||
      !siniestroValues.pais ||
      !siniestroValues.provincia ||
      !siniestroValues.localidad ||
      !siniestroValues.codigoPostal ||
      !siniestroValues.calle ||
      !siniestroValues.numero) {
      return nav("/siniestros/vehiculos")
    }
  }, [])


  const handleSelectTipo = (tipo: TypeTipoSiniestro) => {
    setSiniestroValues({
      ...siniestroValues,
      tipoSiniestro: tipo
    });

    //setSteps({ step: 1, subStep: 1 });
    return nav("/siniestros/vehiculos/formulario")
  };

  return (
    <div className="row justify-content-center pt-4 pb-4">
      <div className="col-md-6">
        <h4 className="text-center mb-4">Seleccione el tipo de siniestro</h4>

        <div className="d-grid gap-3 my-4">
          <SegButton
            label="Accidente de tránsito"
            onClick={() => handleSelectTipo('AccidenteTransito')}
            className="siniestro-button"
            type={'button'}
            disabled={false}
            bgColor={(siniestroValues.tipoSiniestro === "AccidenteTransito") ? (config.origen !== "SEGURARSE") ? `${config.colors.primary} !important` : "#2C7DFD !important" : undefined}
            textColor={siniestroValues.tipoSiniestro === "AccidenteTransito" ? '#fff !important' : undefined}
          />

          <SegButton
            label="Rotura de cristales/cerraduras"
            onClick={() => handleSelectTipo('RoturaCristalesCerraduras')}
            className="siniestro-button"
            type={'button'}
            disabled={false}
            bgColor={siniestroValues.tipoSiniestro === "RoturaCristalesCerraduras" ? (config.origen !== "SEGURARSE") ? `${config.colors.primary} !important` : "#2C7DFD !important" : undefined}
            textColor={siniestroValues.tipoSiniestro === "RoturaCristalesCerraduras" ? '#fff !important' : undefined}
          />

          <SegButton
            label="Robo total"
            onClick={() => handleSelectTipo('RoboTotal')}
            className="siniestro-button"
            type={'button'}
            disabled={false}
            bgColor={siniestroValues.tipoSiniestro === "RoboTotal" ? (config.origen !== "SEGURARSE") ? `${config.colors.primary} !important` : "#2C7DFD !important" : undefined}
            textColor={siniestroValues.tipoSiniestro === "RoboTotal" ? '#fff !important' : undefined}
          />

          <SegButton
            label="Robo parcial"
            onClick={() => handleSelectTipo('RoboParcial')}
            className="siniestro-button"
            type={'button'}
            disabled={false}
            bgColor={siniestroValues.tipoSiniestro === "RoboParcial" ? (config.origen !== "SEGURARSE") ? `${config.colors.primary} !important` : "#2C7DFD !important" : undefined}
            textColor={siniestroValues.tipoSiniestro === "RoboParcial" ? '#fff !important' : undefined}
          />

          <SegButton
            label="Daño parcial (Granizo, inundación, incendio)"
            onClick={() => handleSelectTipo('DanioParcial')}
            className="siniestro-button"
            type={'button'}
            disabled={false}
            bgColor={siniestroValues.tipoSiniestro === "DanioParcial" ? (config.origen !== "SEGURARSE") ? `${config.colors.primary} !important` : "#2C7DFD !important" : undefined}
            textColor={siniestroValues.tipoSiniestro === "DanioParcial" ? '#fff !important' : undefined}
          />
        </div>
      </div>
    </div>
  );
};
