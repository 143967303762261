import { SegSvg } from '../SegSvg/SegSvg'
import styled from '@emotion/styled'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';


interface SegStepperProps {
    label: string
    icon?: "auto" | "moto" | "prepaga" | "art" | "caucion" | "moto"| string
    stepActive?: 1 | 2
    labelColor?: string
    iconColor?: string
    bgColor?: string
    stepActiveBgColor?: string
    stepInactiveBgColor?: string
    stepActiveTextColor?: string
    stepInactiveTextColor?: string
    stepActiveBorderColor?: string
    stepInactiveBorderColor?: string
    lineColor?: string
    className?: string
    isStepper?: boolean
    finalStep?: "$" | "art" | string
}

export const SegStepper = ({
    label,
    icon,
    stepActive,
    labelColor = "#fff",
    iconColor = "#fff",
    bgColor = "#2C7DFD",
    stepActiveBgColor = "#fff",
    stepInactiveBgColor = "#2C7DFD",
    stepActiveTextColor = "#2C7DFD",
    stepInactiveTextColor = "#fff",
    stepActiveBorderColor = "#2C7DFD",
    stepInactiveBorderColor = "#fff",
    lineColor = "#fff",
    className,
    isStepper = true,
    finalStep,
    ...rest
}: SegStepperProps) => {
    const Container = styled.div`
    background-color: ${bgColor} !important
    `

    type StepProps = {
        active: boolean
    }

    const Step = styled.div<StepProps>`
    background-color: ${props => props.active ? stepActiveBgColor + " !important" : stepInactiveBgColor + " !important"};
    color: ${props => props.active ? stepActiveTextColor + " !important" : stepInactiveTextColor + " !important"};
    border-color: ${props => props.active ? stepActiveBorderColor + " !important" : stepInactiveBorderColor + " !important"};
    `;

    const Label = styled.h2`
    color: ${labelColor} !important 
    `;

    const Icon = styled.svg`
    color : ${iconColor} !important
    `;

    const Line = styled.div`
    &:before {
        background-color : ${lineColor} !important
    }
    `;

    return (
        <div className={className} {...rest}>
            <Container className="cot-header text-center">
                <div className="container">
                    <Label className={isStepper === false ? "m-0 fs-20 fs-lg-30 fw-bold text-white mx-auto maxw-90 maxw-lg-100" : "title"}>{label}</Label>
                    {isStepper === false ? "" : <Line className={`steps d-flex justify-content-between align-items-center ${stepActive === 1 ? "step1-on" : "step2-on"}`}>
                        <Step className="step" active={stepActive === 1 ? true : false}>1</Step>
                        <Step className="step" active={stepActive === 2 ? true : false}>2</Step>
                        <Step className="step step-final" active={false}>{finalStep === "$" ? "$" : finalStep === "art" ? <VerifiedUserIcon style={{ verticalAlign: "-3px" }} /> : finalStep}</Step>
                        <Icon className="car"><use xlinkHref={icon === "auto" ? "#ico-auto" : icon === "moto" ? "#ico-moto" : icon === "prepaga" ? "#ico-prepaga" : icon === "art" ? "#ico-art" : "#ico-hombre"} /></Icon>
                    </Line>}
                </div>
            </Container>
            <SegSvg />
        </div>
    )
}